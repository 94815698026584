@import 'assets/styles/config/mixin.scss';

.btnGroup {
	padding: 0.5rem;
	display: inline-flex;
	gap: 0.5rem;
	background-color: $c-white;
	border-radius: 3rem;
	box-shadow: 0 0.625rem 0.938rem 0 rgba($c-primary, 0.2);

	& > button {
		height: 2rem;
		padding: 0 1rem;
		border: 1px solid lightgray;
		border-radius: 2rem;
		font-size: 1.125rem;
		font-weight: 500;
		color: #78787b;
		cursor: pointer;
		transition: all 150ms ease-in-out;

		&:hover {
			background-color: darken($c-white, 5%);
		}

		&.active {
			background-color: $c-primary;
			color: $c-white;
		}
	}
}
