@import 'assets/styles/config/variables.scss';

.tickets {
	display: flex;
	gap: 0.5rem;

	&Header {
		margin: 0 0 1.2rem 0;
		display: flex;
		justify-content: space-between;
	}

	&Card {
		flex: 1;
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);
		display: flex;
		flex-direction: column;
	}

	&LastComment {
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);
		display: flex;
		flex-direction: column;
	}

	&Status {
		margin: 0 0 0.5rem 0;
		& > span {
			border: 1px solid rgba($c-black, 0.2);
			padding: 0.1rem 0.3rem;
			border-radius: 0.2rem;
			background-color: rgba($c-black, 0.03);
			max-width: max-content;
			font-size: 0.9rem;
		}
	}

	.isWaitingStatus {
		& > span {
			font-weight: 700;
			color: $c-black;
			border: 1px solid rgba($c-black, 0.2);
			background-color: rgba(#fbc02d, 0.5);
		}
	}

	.isClosedStatus {
		& > span {
			font-weight: 400;
			color: $c-black;
			border: 1px solid rgba($c-black, 0.2);
			background-color: darken($c-light, 5%);
		}
	}
}

.detail {
	&Wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 0.5rem;

		&.scrollable {
			display: grid;
			grid-template-columns: repeat(auto-fill, 16.594rem);
			grid-auto-flow: column;
			grid-auto-columns: 16.594rem;
			overflow-x: hidden;
			user-select: none;
			scroll-snap-type: x mandatory;

			.detailItem {
				cursor: pointer;
			}
		}
	}

	&Item {
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);

		& * {
			margin: 0;
			padding: 0;
		}

		&Header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			height: 2rem;
		}
	}
}

.table {
	text-align: left;
	border-collapse: collapse;
	width: 100%;

	th,
	td {
		border-bottom: 1px solid var(--surface-400);
		padding: 1.5rem 0;
	}

	tr:last-child {
		& > td {
			border-bottom: none;
		}
	}

	th {
		text-transform: uppercase;
		color: var(--surface-400);
	}

	th.action {
		color: $c-black;
		text-align: center;
	}

	td {
		vertical-align: top;
	}

	tr.info {
		th,
		td {
			border-bottom: none;
			padding-bottom: 0rem;
		}

		.old,
		.new {
			display: inline-flex;
			width: 3.5625rem;
			height: 2rem;
			font-size: 1rem;
			border-radius: 0.25rem;
			font-weight: 600;
			align-items: center;
			justify-content: center;
		}

		.old {
			color: $c-black;
			border: 1px solid $c-black;
		}

		.new {
			color: $c-white;
			border-color: $c-success;
			background-color: $c-success;
		}
	}

	.productInfo {
		display: flex;
		gap: 1rem;

		&Container {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			h6 {
				margin: 0;
			}

			& > span {
				font-size: 0.875rem;
				color: #555;
			}

			&Color {
				display: flex;
				align-items: center;
				gap: 0.5rem;

				& > span {
					display: inline-block;
					width: 0.8125rem;
					height: 0.8125rem;
					border: 1px solid #d1d3d4;
					border-radius: 0.25rem;
				}
			}
		}
	}

	.printFile {
		&Wrapper {
			display: grid;
			gap: 1rem;
			grid-template-columns: 7.8125rem 7.8125rem;
		}

		&Item {
			border: 1px solid var(--surface-400);
			width: 7.8125rem;

			&ImgContainer {
				height: 8.875rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border-bottom: 1px solid var(--surface-400);

				& > img {
					max-width: 100%;
					max-height: 100%;
					object-fit: contain;
					object-position: center;
				}
			}

			&Info {
				padding: 0.75rem;
			}
		}
	}
}

.reason {
	&Wrapper {
		display: flex;
		flex-direction: column;
		align-items: self-start;
		gap: 0.5rem;
		margin-top: 1rem;

		&Completed {
			& > li {
				color: $c-success;
				margin-left: 1.5rem;
			}
		}
	}

	&Item {
		font-size: 1rem;
		background-color: $c-bg-grey;
		padding: 0 1rem;
		height: 2.25rem;
		display: inline-flex;
		border-radius: 0.25rem;
		align-items: center;

		& > .itemCloseIconWrapper {
			margin-left: 1rem;
			width: 2rem;
			height: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			height: 100%;
			border-radius: 0 0.25rem 0.25rem 0;

			&:hover,
			&:focus,
			&:focus-within,
			&:focus-visible,
			&:active {
				background-color: darken($c-bg-grey, 5%);
			}
		}
	}
}
