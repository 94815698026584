.balanceCard {
	display: flex;
	flex-direction: row;
	padding: 1rem;
	background-color: #f7f7f7;

	.sellerInfos {
		flex: 2;
		display: flex;
		flex-direction: column;
	}

	.balanceInfo {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
}

.buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: .5rem;
	margin: .5rem 0;
}

.inputs {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	margin: 1rem 0;
}

.amountInput {
	display: flex;
	flex-direction: column;
}

.descriptionInput {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.panelHeader {
	overflow: hidden;
}

.tab {
	background-color: #f7f7f7;
	padding: 1rem;

	&Description {
		display: block;
	}

	&Warn {
		display: block;
		background-color: #ffd965;
		font-weight: 500;
		padding: .75rem;
		margin: .5rem 0 1rem 0;
	}

	&Btn {
		display: flex;
		justify-content: flex-end;
	}
}

.title {
	display: inline-block;
	background-color: #f7f7f7;
	padding: .8rem 1.2rem;
	font-weight: 700;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
}