@import 'assets/styles/config/mixin.scss';

.dialog {
	--footer-height: 4rem;

	&:global(.p-dialog .p-dialog-header .p-dialog-title) {
		font-size: 1.5rem;
	}

	:global(.pi.pi-times) {
		font-size: 1.25rem;
	}

	&:global(.p-dialog.p-component) {
		width: 100%;
		height: 100%;
	}

	&:global(.p-dialog) {
		max-height: 100% !important;
	}

	.wrapper {
		--side-width: 25rem;
		display: flex;
		width: 100%;
		height: calc(100% - var(--footer-height));
		border-top: 1px solid var(--surface-400);
		position: relative;

		.info {
			padding: 1rem;

			border-right: 1px solid var(--surface-400);
			width: var(--side-width);
			position: fixed;
			top: 4.5rem;
			overflow-y: auto;
			height: calc(100vh - var(--footer-height) - 4.5rem);

			h5 {
				font-size: 1.125rem;
				margin: 0;

				& > span {
					display: inline-block;
					width: 70%;
					padding-bottom: 6px;
					border-bottom: 3px solid $c-primary;
				}
			}

			& > .productInfo {
				margin-top: 1rem;

				.color {
					&Wrapper {
						display: flex;
						flex-wrap: wrap;
						gap: 0.75rem;
						margin-bottom: 1.5rem;
					}

					&Item {
						width: 2rem;
						height: 2rem;
						border-radius: 0.25rem;
						background-color: var(--color-item-bg-color);
						cursor: pointer;
						transition: box-shadow 100ms ease-in-out;
						border: 0.4px solid #d9d9d9;

						&.selected {
							box-shadow: 0 0 0 2px $c-light, 0 0 0 4px $c-black;
						}
					}
				}

				.changeProductBtn {
					background-color: $c-grey !important;
					border: none;
				}

				.size {
					&Wrapper {
						display: flex;
						flex-wrap: wrap;
						gap: 0.75rem;
						margin-bottom: 1.5rem;
					}

					&Item {
						min-width: 2.1875rem;
						height: 2.1875rem;
						border-radius: 0.25rem;
						border: 1px solid $c-black;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						transition: all 100ms ease-in-out;

						&.selected {
							background-color: $c-primary;
							color: $c-light;
							border-color: $c-primary;
						}
					}
				}

				.side {
					&Wrapper {
						display: flex;
						flex-direction: column;
						flex-wrap: wrap;
					}

					&Item {
						height: 2.1875rem;
						display: flex;
						align-items: center;
						justify-content: space-between;
						border-bottom: 1px solid var(--surface-400);

						&:last-child {
							border-bottom: none;
						}
					}
				}
			}
		}

		.container {
			margin-left: var(--side-width);
			width: calc(100% - var(--side-width));
			max-height: 100%;
			overflow-y: auto;

			.sideTab {
				&Wrapper {
					max-width: 45rem;
					display: flex;
					overflow-x: hidden;
					gap: 0.2rem;
					margin: 1rem auto 0;
					border-bottom: 1px solid var(--surface-400);
					scroll-snap-type: x mandatory;
				}

				&Item {
					display: inline-block;
					scroll-snap-align: start;
					text-wrap: nowrap;
					padding: 0.5rem 1rem;
					cursor: pointer;
					border-bottom: 2px solid transparent;
					transition: border 100ms ease-in-out;

					&:hover {
						background-color: $c-light;
					}

					&.selected {
						border-bottom-color: $c-black;
						pointer-events: none;
					}

					&.disabled {
						pointer-events: none;
						opacity: 0.7;
					}
				}
			}

			.sideTabButtonContainer {
				position: absolute;
				inset: 0;
				display: flex;
				z-index: -1;

				.sideTabButtonContainerPrev,
				.sideTabButtonContainerNext {
					cursor: pointer;
					display: inline-flex;
					align-items: center;
					width: 3rem;
					user-select: none;
				}

				.sideTabButtonContainerPrev {
					background-image: linear-gradient(to right, $c-white 0%, transparent 300%);
					margin-right: auto;

					& > svg {
						margin-left: 0.5rem;
						margin-right: auto;
					}
				}

				.sideTabButtonContainerNext {
					background-image: linear-gradient(to left, $c-white 0%, transparent 300%);
					margin-left: auto;

					& > svg {
						margin-left: auto;
						margin-right: 0.5rem;
					}
				}
			}

			.designWrapper {
				display: flex;
				gap: 2rem;
				// min-height: calc(100% - 3.25rem);
				// max-width: 75%;
				margin: 1rem auto 0;
				justify-content: center;

				.mockupSide {
					width: var(--side-img-width);
					// height: 100%;
					display: flex;
					align-items: center;

					.mockup {
						background-image: var(--side-img-url);
						background-repeat: no-repeat;
						background-size: contain;
						background-color: var(--selected-color);
						width: var(--side-img-width);
						height: var(--side-img-height, 310px);
						position: relative;

						& .canvas {
							position: absolute;
							border: 1px dashed $c-black;
							top: var(--area-y);
							left: var(--area-x);
							background-color: rgba($c-white, 0.1);
						}
					}
				}

				.optionsSide {
					max-width: calc(100% - var(--side-img-width) - 1rem);
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					gap: 2rem;

					.fileArea {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						gap: 0.5rem;

						.deleteDesignBtn {
							width: 100%;
							max-width: 4rem;
							height: 100%;
							color: $c-red;
							border: 2px solid rgba($c-red, 0.3);
							border-radius: 0.5rem;

							&:hover {
								background-color: rgba($c-red, 0.05);
							}
						}

						.printFile {
							&Wrapper {
								display: flex;
								max-width: 25rem;
								border: 2px solid var(--surface-400);
								border-radius: 0.5rem;
								padding: 0.3rem;
								cursor: pointer;
								transition: background-color 100ms ease-in-out;

								&:hover {
									background-color: $c-light;
								}
							}

							&Btn {
								display: flex;
								flex-direction: column;
								gap: 0.5rem;
								align-items: center;
								padding: 0.5rem 1rem;

								& > span {
									font-size: 0.875rem;
									font-weight: 600;
								}
							}

							&Divider {
								width: 0.125rem;
								min-height: 100%;
								margin: 0 1rem 0 0;
								background-color: var(--surface-400);
							}

							&Info {
								display: flex;
								flex-direction: column;
								text-align: center;
								align-items: center;
								justify-content: center;
								padding-right: 0.7rem;

								.printTitle {
									font-size: 0.875rem;
									font-weight: 600;
								}

								.printAccepts {
									font-size: 0.75rem;
									font-weight: 600;
									color: var(--surface-400);
								}
							}
						}
					}

					.sizeInputContainer {
						.inputWrapper {
							&Body {
								display: flex;
								align-items: center;
								gap: 0.5rem;
								margin-top: 0.5rem;

								& .inputGroup {
									display: flex;
									align-items: center;
									justify-content: center;

									& > :global(.pr-input-group) {
										& > label {
											display: none;
										}

										& > input {
											max-width: 6rem;
											border-radius: 0.4rem 0 0 0.4rem;
											height: 2rem;
											border-color: #d1d3d4;
										}
									}

									& > span {
										background-color: #d1d3d4;
										height: 2rem;
										line-height: 1;
										border-radius: 0 0.4rem 0.4rem 0;
										padding: 0 0.5rem;
										display: flex;
										align-items: center;
									}
								}
							}

							&Footer {
								width: 100%;
								text-align: right;
								margin-top: 0.5rem;
							}
						}

						.staticWrapper {
							max-width: 26.793rem;
							width: 100%;
							display: flex;
							gap: 0.5rem;
							align-items: center;
							margin-top: 0.75rem;
							background-color: #d2e7f3;
							color: #4b739d;
							padding: 0.75rem 0.5rem;
							border-radius: 0.5rem;
							border: 2px solid #c0daeb;

							.text {
								font-size: 0.875rem;
							}
						}
					}
				}
			}

			.noSelect {
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 2.5rem;

				.image {
					height: 100%;
					width: 100%;
					max-height: 13.5rem;
					max-width: 27rem;
					background-image: url(../../../../../public/svgs/no_preview.svg);
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
		}
	}

	.footer {
		height: var(--footer-height);
		position: fixed;
		bottom: 0;
		left: 1.25rem;
		right: 1.25rem;
		padding: 0 1.25rem;
		border-top: 1px solid var(--surface-400);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-color: $c-white;
	}
}
