@import 'assets/styles/config/mixin.scss';

.contactDetailsInputs {
	.nameSurname {
		@include mq('tablet') {
			display: flex;
			flex-direction: row;
			gap: 1rem;
		}
	}

	&Name {
		margin: 1rem 0 0 0;
		flex: 1;
	}

	&Surname {
		margin: 1rem 0 0 0;
		flex: 1;
	}

	&Email {
		margin: 1rem 0 0 0;
	}

	.prefixPhone {
		@include mq('tablet') {
			display: flex;
			flex-direction: row;
			gap: 1rem;
		}
	}

	&Prefix {
		margin: 1rem 0 0 0;
		flex: 1;
	}

	&Phone {
		margin: 1rem 0 0 0;
		flex: 3;
	}
}

.addressInputs {
	.countryState {
		@include mq('tablet') {
			display: flex;
			flex-direction: row;
			gap: 1rem;
		}
	}

	&Country {
		margin: 1rem 0 0 0;
		flex: 1;
	}

	&State {
		margin: 1rem 0 0 0;
		flex: 1;
	}

	.cityZipcode {
		@include mq('tablet') {
			display: flex;
			flex-direction: row;
			gap: 1rem;
		}
	}

	&City {
		margin: 1rem 0 0 0;
		flex: 1;
	}

	&Zipcode {
		margin: 1rem 0 0 0;
		flex: 1;
	}

	&Address1 {
		margin: 1rem 0 0 0;
		flex: 1;
	}

	&Address2 {
		margin: 1rem 0 0 0;
		flex: 1;
	}
}

.deviceControl {
	display: none !important;

	@include mq('tablet') {
		display: flex !important;
	}
}

.buttons {
	position: sticky !important;
	bottom: 0;
	display: flex;
	justify-content: flex-end;
}
