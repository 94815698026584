@import 'src/assets/styles/config/mixin.scss';

.prizes {
  /*max-width: 1086px;*/

  .prizeCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: solid 1px $c-border;
    border-radius: 4px;
    padding: 0.5rem;
    height: 21rem;
    margin: 0 .75rem;
    box-sizing: border-box;
  }

  .imageSide {
    flex-basis: 100%;
    max-height: 204px;
    max-width: 271px;
    margin-right: 2rem;
    border-color: $c-border;

    .image {
      width: 100%;
      height: 100%;
      background-image: url(../../../../public/svgs/gift_illustration.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;

    }
  }
}

.sliderWrapper {
  position: relative;

  .prevBtn {
    display: block;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: not-allowed;
    background-color: $c-white;

    @media (min-width: 500px) {
      display: none;
    }
  }

  .nextBtn {
    display: block;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: not-allowed;
    background-color: $c-white;

    @media (min-width: 500px) {
      display: none;
    }
  }

  .swiperItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: .3rem .3rem;
    border-radius: 4px;
    box-shadow: 3px 3px 15px 5px rgba(218, 218, 218, 0.25);
    border: 1px solid transparent;
    transition: 0.2s;
	cursor: pointer;

    @media (max-width: 500px) {
      margin: .3rem 0;
    }

    img {
      top: 0;
      width: 100%;
      height: 251px;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
    }

    &Err {
      top: 0;
      width: 100%;
      height: 251px;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
    }

    &:hover {
      border: 1px solid #101531;
      transition: 0.2s;
    }

    .content {
      padding: 1rem;

      .title {
        margin: 0;
        margin-bottom: 2rem;
        font-weight: 500;
        color: #101531;
      }

      .subtitle {
        margin: 0;
        font-size: .9rem;
        font-weight: 500;
        color: #575555;
      }

      .price {
        margin: 0;
        margin-top: 1rem;
        font-size: .9rem;
        color: #101531;
      }

      .platinium {
        margin: 0;
        font-size: .9rem;
        color: $c-primary;
      }
    }
  }
}