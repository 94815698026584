@import 'assets/styles/config/mixin.scss';

.category {
	&Wrapper {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		@include mq('tablet') {
			grid-template-columns: repeat(3, 1fr);
		}

		@include mq('desktop') {
			grid-template-columns: repeat(2, 1fr);
		}

		@include mq('large') {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&Item {
		text-align: center;
		// height: calc(12.559rem + 4rem);

		// @include mq('large') {
		// 	height: 19.25rem;
		// }

		img {
			width: 100%;
			aspect-ratio: 1;
			object-fit: contain;
			object-position: top;
			display: block;
		}

		&Title {
			height: 4rem;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			color: $c-black;
		}
	}
}
