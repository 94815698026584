.paymentMethodCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 1rem 0;
	padding: .75rem;
	background-color: whitesmoke;

	p {
		font-size: .9rem;
		font-weight: 600;
		margin: .25rem;
	}

	.buttons {
		border: 1px solid lightgray;
		border-radius: 1rem;

		.activeBtn {
			padding: .5rem 1rem;
			color: white;
			background-color: #1565C0;
			border: none;
			cursor: pointer;
		}

		.passiveBtn {
			padding: .5rem 1rem;
			color: black;
			background-color: whitesmoke;
			border: none;
			cursor: pointer;
		}

		.leftBtn {
			border-bottom-left-radius: 1rem;
			border-top-left-radius: 1rem;
		}

		.rightBtn {
			border-bottom-right-radius: 1rem;
			border-top-right-radius: 1rem;
		}
	}
}