.CustomAccordion {
  .p-accordion-header {
    color: black;
    background-color: lightgray;

  }
}

.AccHeader {
  color: black !important;
}