@import 'assets/styles/config/mixin.scss';

.cardsMain {
	display: flex;
	flex-wrap: wrap;

	.cardMain {
		width: 100%;
		padding: 0 0 1rem 0;

		@include mq('tablet') {
			width: 50%;
			padding: 0 1rem 1rem 0;
		}
	}

	.card {
		border: 0.15rem dotted #e6e6e6;
		border-radius: 0.25rem;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		cursor: pointer;

		@include mq('tablet') {
			flex-direction: row;
			height: 7.25rem;
		}

		&Name {
			font-size: 1rem;
			font-weight: 500;
		}

		&Body {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: .5rem;

			&Icon {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background-color: #f9f9f9;
				border-radius: 0.5rem;
				width: 4rem;
				height: 3rem;
			}

			.creditCard {
				margin: 0 1rem;

				&Number {
					font-size: 0.95rem;
					font-weight: 500;
				}

				&Expired {
					font-size: 0.8rem;
					font-weight: 400;
					color: #b5b5c3;
				}
			}
		}

		.btns {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.deleteBtn {
				border: 0;
				border-radius: 0.5rem;
				background-color: #f9f9f9;
				color: #78829d;
				font-weight: 500;
				padding: 0.75rem;
				cursor: pointer;
			}
		}
	}

	.addCardMain {
		width: 100%;
		padding: 0 0 1rem 0;

		@include mq('tablet') {
			width: 50%;
			padding: 0 1rem 1rem 0;
		}

		.addCard {
			border: 0.13rem dotted #e6e6e6;
			border-radius: 0.25rem;
			background-color: rgba(#4541FF, 0.05);
			padding: 2rem 1rem;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			cursor: pointer;

			@include mq('tablet') {
				flex-direction: row;
				height: 7.25rem;
			}

			.addCardBody {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;

				h5 {
					font-size: 1.2rem;
					margin: 0;
				}

				p {
					font-size: 0.9rem;
					line-height: 1rem;
				}
			}

			.addCardBodyNew {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;

				h5 {
					margin: 0;
					color: #123f72;
					font-weight: 500;
				}
			}

			.btns {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 1rem 0 0 0;

				@include mq('tablet') {
					align-items: flex-end;
					padding: 0;
				}

				.addBtn {
					background-color: #4050b5;
					border: 0;
					border-radius: 0.5rem;
					color: white;
					margin: 0 0 0 0.5rem;
					padding: 0.8rem;
					width: 6rem;
					cursor: pointer;
				}
			}
		}
	}
}

.noCardContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4rem 0;

	.noCardMain {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		width: 12rem;

		h3 {
			color: #071437;
		}

		.addCard {
			color: #bfbfcb;
			font-weight: 500;
		}
	}
}

.selectedCard {
	border: 1px solid #4541FF !important;
}

.navigationBtns {
	display: none !important;

	@include mq('tablet') {
		display: flex !important;
	}
}
