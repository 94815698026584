.dropdown {
	position: relative;
	user-select: none;

	.menu,
	button {
		&:hover,
		&:focus,
		&:focus-visible,
		&:focus-within {
			outline: 0;
		}
	}

	.menu {
		position: absolute;
		list-style-type: none;
		margin: 5px 0;
		padding: 0;
		border: 1px solid #e5e5e5;
		border-radius: 4px;
		z-index: 1;
		min-width: 100%;

		&.left {
			right: 0;
		}

		&.right {
			left: 0;
		}

		& > li {
			margin: 0;
			background-color: white;

			&:hover {
				background-color: #e9ecef;
			}

			& > button {
				width: 100%;
				height: 100%;
				text-align: left;
				background: none;
				color: inherit;
				border: none;
				padding: 0.75rem 1.25rem;
				margin: 0;
				font: inherit;
				cursor: pointer;
				white-space: nowrap;

				&:disabled {
					pointer-events: none;
					color: lighten(#000000, 50%) !important;
				}
			}
		}
	}
}
