.productListItem {
	display: flex;
	align-items: center;
	padding: 1rem 1rem 1rem 0;
	width: 100%;

	img {
		width: 150px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 2rem;
	}

	.productListDetail {
		flex: 1 1 0;
		margin-left: 1rem;
	}

	.productListAction {
		display: flex;
		flex-direction: column;
	}

	.productPrice {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: 0.5rem;
		align-self: flex-end;
	}
}

.productHeaderWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.productName {
		font-size: 1.5rem;
		font-weight: 700;
	}

	.productStatus {
		padding: 0.5rem 1.4rem;
		border-radius: 0.6rem;
		border: 1px solid var(--statu-color, #000);
		color: var(--statu-color, #000);
		background-color: lighten(grey, 45%);
		border-radius: 0.6rem;
		font-weight: 500;
		line-height: 1;
	}
}

.productDescription {
	margin: 0 0 1rem 0;
}

.productCategoryIcon {
	vertical-align: middle;
	margin-right: 0.5rem;
}

.productCategory {
	font-weight: 600;
	vertical-align: middle;
}
