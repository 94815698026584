@import 'assets/styles/config/mixin.scss';

.table {
	text-align: left;
	border-collapse: collapse;
	width: 100%;

	th,
	td {
		border-bottom: 1px solid var(--surface-400);
		padding: 1.5rem 0;
	}

	th {
		text-transform: uppercase;
		color: var(--surface-500);
	}

	td {
		vertical-align: top;
	}

	tr:last-child {
		& > td {
			border-bottom: none;
		}
	}

	.productInfo {
		display: flex;
		gap: 1rem;

		&Container {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			h6 {
				margin: 0;
			}

			& > span {
				font-size: 0.875rem;
				color: #555;
			}

			&Color {
				display: flex;
				align-items: center;
				gap: 0.5rem;

				& > span {
					display: inline-block;
					width: 0.8125rem;
					height: 0.8125rem;
					border-radius: 0.25rem;
					border: 1px solid #d1d3d4;
				}
			}
		}
	}

	.printFile {
		&Wrapper {
			display: grid;
			gap: 1rem;
			grid-template-columns: 7.8125rem 7.8125rem;
		}

		&Item {
			border: 1px solid var(--surface-400);
			width: 7.8125rem;

			&ImgContainer {
				height: 8.875rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border-bottom: 1px solid var(--surface-400);

				& > img {
					max-width: 100%;
					max-height: 100%;
					object-fit: contain;
					object-position: center;
				}
			}

			&Info {
				padding: 0.3rem 0.75rem;
			}
		}
	}
}
