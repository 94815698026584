@import 'assets/styles/config/mixin.scss';


.ticketMain {
	display: flex;
	flex-direction: column-reverse;

	@include mq('xx-large') {
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 1rem;
	}
}

.ticketCards {
	width: 100%;

	@include mq('xx-large') {
		width: 20rem;
	}

	.ticketCard {
		background-color: #fafafa;
		border: 1px solid #eaeaea;
		padding: .5rem;
		position: relative;

		&:not(:first-child) {
			margin-top: .5rem;
		}

		span {
			display: block;
			margin-top: .5rem;
		}

		.orderLink {
			position: absolute;
			cursor: pointer;
			right: .6rem;
			top: .6rem;
		}
	}
}

.stickyCodes {
	position: sticky !important;
	right: 0;
	top: 5rem;
}