@import 'assets/styles/config/mixin.scss';

.connections {
	&Table {
		&Wrapper {
			box-shadow: 0 0.2rem 0.5rem rgba($c-black, 0.05);
			border-bottom: 1px solid rgba($c-black, 0.2);
			padding: 0 1.5rem 0 1.5rem;
		}

		&Header {
			display: flex;
			gap: 1.5rem;
			align-items: center;
			height: 4.2rem;

			&Titles {
				flex: 1 1 calc(100% - 19rem);
				display: flex;
				align-items: center;
				gap: 1rem;

				&Seller {
					flex: 1 1 30%;
				}

				&Store {
					flex: 1 1 25%;
				}

				&Status {
					flex: 1 1 10%;
				}

				&Date {
					flex: 1 1 25%;
				}

				&Actions {
					flex: 1 1 10%;
				}
			}
		}

		&Body {
			position: relative;

			&Wrapper {
				padding: 1rem 0;
				display: flex;
				align-items: center;
			}

			&Items {
				flex: 1 1 calc(100% - 20rem);
				display: flex;
				align-items: center;
				gap: 1rem;
			}

			&Item {
				&Wrapper {
					display: block;
					transition: background-color 100ms ease-in-out;

					&:not(:first-child) {
						border-top: 1px solid rgba($c-black, 0.2);
					}

					&:hover {
						background-color: darken($c-white, 4%);

						& .ordersTableBodyItems {
							h6 {
								color: $c-primary;
							}

							& .ordersTableBodyItemInventory {
								color: $c-primary;
							}
						}
					}

					&.loadMore {
						min-height: 5rem;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: space-evenly;
						gap: 0.5rem;
						cursor: auto;

						&:hover {
							background-color: transparent;
						}
					}
				}

				&Seller {
					flex: 1 1 30%;
					display: flex;
					flex-direction: column;
					gap: 0.3rem;
				}

				&Store {
					flex: 1 1 25%;
					font-size: 0.875rem;

					span {
						display: block;
						max-width: 15rem;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: .25rem;
						font-size: 1rem;
						font-weight: 400;
					}
				}

				&Status {
					flex: 1 1 10%;

					& > span {
						border: 1px solid rgba($c-black, 0.2);
						padding: 0.1rem 0.3rem;
						border-radius: 0.2rem;
						background-color: rgba($c-black, 0.03);
						max-width: max-content;
						margin-top: 0.3rem;
						font-size: .9rem;
					}
				}

				&Date {
					flex: 1 1 25%;
					font-size: 0.875rem;
				}

				&Actions {
					flex: 1 1 10%;
				}
			}
		}
	}
}

.isActiveStatus {
		& > span {
		font-weight: 500;
		color: $c-black;
		border: 1px solid rgba($c-black, 0.2);
		background-color: rgba(green, 0.2);
	}
}