@import 'assets/styles/config/variables.scss';

.headerTitles {

	h1 {
		margin:0;
		font-size: 2rem;
		font-weight: 700;
		white-space: nowrap;
	}
}

.dtf {
	display: flex;
	gap: 0.5rem;

	&Header {
		margin: 0 0 1.2rem 0;
	}

	&Card {
		flex: 1;
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);
		display: flex;
		flex-direction: column;
	}
}

.detail {
	&Wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 0.5rem;

		&.scrollable {
			display: grid;
			grid-template-columns: repeat(auto-fill, 16.594rem);
			grid-auto-flow: column;
			grid-auto-columns: 16.594rem;
			overflow-x: hidden;
			user-select: none;
			scroll-snap-type: x mandatory;

			.detailItem {
				cursor: pointer;
			}
		}
	}

	&Item {
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);

		& * {
			margin: 0;
			padding: 0;
		}

		&Header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			height: 2rem;
		}
	}
}

.orderDtfDesigns {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	&Card {
		display: flex;
		flex-direction: column;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);


		&Title {
			background-color: lighten($c-light, 1%);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 1rem;
			font-weight: 700;
			font-size: 1.1rem;
		}

		&Designs {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 1rem;
			padding: 1rem;
		}
	}
}

.warn {
	display: flex;
	align-items: center;
	padding: .75rem;
	gap: .5rem;
	background-color: lighten($c-light, 1%);
	border-radius: 0.2rem;
	border: 1px solid darken($c-light, 5%);
	margin-bottom: 1rem;
}