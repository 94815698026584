@import 'assets/styles/config/mixin.scss';

.prices {
	&Wrapper {
		width: min(35rem, 100%);
		display: flex;
		flex-direction: column;
	}

	&Item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.125rem;
		padding: 0.5rem 0;
		border-bottom: 1px solid var(--surface-400);
	}

	&Title {
		font-weight: 600;
	}

	&Item:last-child {
		border-bottom: none;

		& > .pricesValue {
			font-weight: 600;
		}
	}
}
