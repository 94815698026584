@import 'assets/styles/config/mixin.scss';

.product {
	&Wrapper {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;

		@include mq('tablet') {
			grid-template-columns: repeat(3, 1fr);
		}

		@include mq('desktop') {
			grid-template-columns: repeat(2, 1fr);
		}

		@include mq('large') {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&Item {
		&ImgWrapper {
			width: 100%;

			& > img {
				width: 100%;
				aspect-ratio: 1;
				object-fit: contain;
				object-position: top;
				display: block;
			}
		}

		&Body {
			padding: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			color: $c-black;
			gap: 0.2rem;
			height: 15rem;

			@include mq('tablet') {
				height: 12rem;
			}

			@include mq('desktop') {
				height: 14rem;
			}

			@include mq('large') {
				height: 12rem;
			}

			& > * {
				margin: 0;
			}
		}
	}
}
