@import 'assets/styles/config/mixin.scss';

.detail {
  &Wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
  }

  &Item {
    background-color: lighten($c-light, 1%);
    padding: 0.5rem;
    border-radius: 0.2rem;
    border: 1px solid darken($c-light, 5%);
    z-index: 100;

    & * {
      margin: 0;
      padding: 0;
    }

    &Header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      height: 2rem;
    }
  }
}
