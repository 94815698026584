@import '../../../../../../assets/styles/config/mixin.scss';

.container {
	border: 1px solid #d1d3d4;
	border-radius: 0.25rem;
	background-color: $c-white;
    height: var(--con-height, 'max-content');
	padding: 0 1rem;
    max-width: 100%;
	min-width: 14.5rem;
	position: relative;

    transition: 0.5s;

	@include mq('tablet') {
		max-width: 24%;
		min-width: 14.5rem;
	}

    .titleView {
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        margin-left: .8rem;
        margin-right: .8rem;

        .title {
            font-weight: 700;
            font-size: 20px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }
    }

    .pricingView {
        display: flex;
        justify-content: center;
        margin-left: .8rem;
        margin-right: .8rem;
		font-weight: 700;
		font-size: 20px;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

	.includes {
		margin: 1rem 0;
		font-weight: 500;
	}

    .featuresView {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
        height: var(--features-height, 'max-content');

        .featureView {
            display: flex;
            gap: 2px;
            margin-top: 6px;

            .feature {
                font-size: .9rem;
                margin-left: .25rem;
            }
        }
    }

    .button {
       display: flex;
       justify-content: center;
       background-color: $c-white;
       margin: 0 3rem;
       padding: .8rem;
	   border: 1px solid rgba(0,0,0,.2);
       border-radius: .5rem;
       text-align: center;
       transition: 0.5s;

        &Main {
            border: none;
            background: none;

            &Text {
                font-size: 1rem;
                font-weight: 600;
            }
        }
    }
    .button:hover {
        cursor: pointer;

        .buttonMainText:hover {
            cursor: pointer;
        }
    }

    .footerView {
        margin: 1rem 0;
        text-align: center;

        .footerDescription {
            font-size: .75rem;
            line-height: .8rem;
        }
    }
}

.nonWhite {
	background-color: #ebf0ff;
}

.yourPlan {
	--height: 4rem;
	width: 4rem;
	border-bottom-right-radius: 10rem;
	position: absolute;
	height: var(--height);
	left: 0px;
	background-color: $c-primary;
	border: 1px solid $c-primary;
	text-align: center;
	line-height: 1rem;
	color: $c-white;
	font-size: .9rem;
	font-weight: 600;
	box-shadow: 0px 2px 5px 0px rgba($c-primary, 0.25);

	&Text {
		width: 3rem;
		position: absolute;
		top: 10px;
		left: 3px;
		transform: rotate(-45deg);
	}
}