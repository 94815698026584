@import './config/mixin.scss';

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
button,
div,
section,
aside {
	color: $c-black;
	font-family: 'Roboto', sans-serif;
}

.container {
	max-width: 70rem;
	width: 100%;
	margin: 0 auto;

	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		@include mq('tablet') {
			flex-wrap: nowrap;
		}

		&-title {
			font-size: 2rem;
			min-width: max-content;
			margin: 0;
		}

		&-tools {
			margin: 0;
			width: 100%;
			display: flex;
			align-items: center;
			gap: 1rem;
			margin-top: 1rem;

			@include mq('tablet') {
				margin-top: 0;
				justify-content: flex-end;
			}
		}
	}

	&-body {
		margin-top: 1rem;
		padding: 1.5rem 0 0;
		background-color: $c-white;
		border: 1px solid rgba($c-black, 0.2);
		position: relative;
		z-index: 0;
	}
}

.pr {
	&-btn {
		display: inline-block;
		padding: 0 1rem;
		text-align: center;
		color: $c-black;
		background-color: $c-white;
		font-weight: 500;
		border: 1px solid rgba($c-black, 0.2);
		border-radius: 0.2rem;
		line-height: 0;
		user-select: none;
		cursor: pointer;
		transition: all 100ms ease-in-out;
		display: flex;
		align-items: center;
		min-width: max-content;
		height: 2.375rem;
		font-size: 1rem;

		&:disabled,
		&.disabled {
			opacity: 0.7;
			pointer-events: none;
		}

		& > &-text {
			min-width: max-content;
		}

		&.pr-btn-loading {
			pointer-events: none;

			& > .pi.pi-fw.pi-sync {
				-webkit-animation: rotating 1s linear infinite;
				-moz-animation: rotating 1s linear infinite;
				-ms-animation: rotating 1s linear infinite;
				-o-animation: rotating 1s linear infinite;
				animation: rotating 1s linear infinite;
			}
		}

		&-secondary {
			color: $c-black;
			background-color: $c-white;
			border-color: rgba($c-black, 0.2);

			&:hover {
				color: $c-primary;
			}
		}

		&-primary {
			color: $c-light;
			background-color: $c-primary;
			border-color: rgba($c-primary, 0.2);

			&:hover {
				background-color: darken($c-primary, 10%);
			}
		}

		&-success {
			color: $c-light;
			background-color: $c-success;
			border-color: rgba($c-success, 0.2);

			&:hover {
				background-color: darken($c-success, 10%);
			}
		}

		&-danger {
			color: $c-light;
			background-color: $c-red;
			border-color: rgba($c-red, 0.2);

			&:hover {
				background-color: darken($c-red, 10%);
			}
		}

		&-icon {
			border: none;
			padding: 0;
			height: 30px;
			width: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;

			&:hover {
				background-color: darken($c-light, 10%);
			}
		}

		&-small {
			height: 2rem;
			font-size: 0.9rem;
		}

		&-large {
			height: 2.5rem;
			font-size: 1.1rem;
		}
	}

	&-dropdown {
		position: relative;
		width: max-content;

		&-menu {
			position: absolute;
			display: block;
			top: calc(100% + 0.3rem);
			right: 0;
			flex-direction: column;
			padding: 0.5rem;
			gap: 0.5rem;
			background-color: $c-white;
			box-shadow: 0 0 5px rgba($c-black, 0.2);
			border-radius: 0.2rem;
			max-height: 13rem;
			overflow-y: auto;
			min-width: max-content;
			overflow-x: hidden;
			z-index: 1;

			&.right {
				left: 0;
			}

			&-item {
				border: none;
				text-align: left;
				width: 100%;

				&:hover {
					background-color: $c-light;
				}

				&.active-item {
					background-color: darken($c-light, 3%);
				}
			}
		}
	}

	&-input {
		&-group {
			display: flex;
			flex-direction: column;
			gap: 0.3rem;
		}

		&-label {
			display: flex;
			flex-direction: column;

			&-title {
				margin: 0;
				font-size: 1.2rem;
			}

			&-required {
				color: $c-red;
			}

			&-optional {
				color: var(--text-color-secondary);
				font-size: 500;
				font-size: 1rem;
			}

			&-description {
				color: var(--text-color-secondary);
			}
		}

		&-control {
			width: 100%;
			border: 1px solid var(--surface-400);
			border-radius: 0.6rem;
			height: 40px;
			line-height: 1;
			margin: 0;
			padding: 0 0.8rem;
			font-size: 1rem;
		}

		&-textarea-control {
			width: 100%;
			border: 1px solid var(--surface-400);
			border-radius: 0.6rem;
			min-height: 50px;
			line-height: 1;
			margin: 0;
			padding: 0.8rem;
			font-size: 1rem;
			resize: vertical;
			font-family: 'Roboto', sans-serif;
		}

		&-invalid {
			border-color: $c-red;

			&:focus-visible {
				outline-color: $c-red;
			}
		}

		&-info {
			display: flex;
			flex-direction: column;

			& > .check-character {
				text-align: end;
				color: var(--surface-600);
				font-size: 0.9rem;
				font-weight: 600;
			}

			& .error-message {
				color: $c-red;
				font-size: 0.9rem;
				font-weight: 500;
			}
		}
	}

	&-price-input-control {
		.p-inputtext.p-component.p-inputnumber-input {
			padding: 0 0.8rem;
			font-size: 1rem;
			line-height: 1;
			height: 40px;
			width: 100%;
			border: 1px solid var(--surface-400);
			border-radius: 0.6rem;
			margin: 0;
			background-color: $c-white;

			&:focus {
				background-image: none;
				outline: -webkit-focus-ring-color auto 1px;
			}
		}
	}

	&-loading,
	&-loading-sticky {
		position: absolute;
		background-color: rgba($c-black, 0.5);
		inset: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
		z-index: 99;

		h3 {
			color: var(--primary-color-text) !important;
		}

		.p-progress-color {
			svg circle {
				stroke: var(--primary-color-text) !important;
			}
		}
	}

	&-loading-sticky {
		display: block;

		.pr-spinner-wrapper {
			position: sticky;
			top: 4rem;
			left: 0;
			max-height: 100vh;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: $c-light;
		}
	}

	&-table {
		border-radius: 0.2rem;

		& .p-checkbox {
			background-color: $c-primary !important;
		}

		& .p-datatable-tbody .p-highlight {
			background-color: transparent !important;
			color: $c-black !important;
		}

		& .p-datatable-thead > tr > th {
			background-color: lighten($c-black, 83%);
		}
	}
}

/* Safari and Chrome */
@-webkit-keyframes rotating {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
