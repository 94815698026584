@import 'assets/styles/config/mixin.scss';

.printFile {
  &Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 2px dashed;
    border-color: var(--surface-400);
    height: 290px;
    border-radius: 0.5rem;
    padding: 0.3rem;
    cursor: pointer;
    //  background-color: rgba($c-primary, 0.1);
    background-color: transparent;
    transition: 200ms ease-in-out;

    &:hover {
      border-color: $c-primary;
      background-color: rgba($c-primary, 0.1);
    }
  }

  &Btn {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem 1rem;

    & > span {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

  &Cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow: auto;

    &Card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      height: 90%;
      width: 213px;
      border-radius: 0.5rem;
      margin: .5rem .5rem;
      box-shadow: 3px 3px 10px rgba($c-black, 0.1);

      &Content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 160px;
        margin-bottom: .25rem;
      }

      &Footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: auto;
        font-size: .75rem;

        p {
          margin: 0;
        }
      }

      &Delete {
        border: 1px solid var(--surface-400);
        border-radius: 0.2rem;
        margin: .5rem .5rem .5rem auto;
        padding: .3rem;
        transition: background-color 100ms ease-in-out;

        &:hover {
          background-color: var(--surface-100);
        }
      }
    }
  }

  &Divider {
    width: 0.125rem;
    min-height: 100%;
    margin: 0 1rem 0 0;
    background-color: var(--surface-400);
  }

  &Text {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--gray-600);
  }

  &Info {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-right: 0.7rem;

    .printTitle {
      font-size: 0.875rem;
      font-weight: 600;
    }

    .printAccepts {
      font-size: 0.75rem;
      font-weight: 600;
      color: var(--surface-400);
    }
  }
}

.Item {
  display: flex;
  flex-direction: column;

  &Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;

    &Small {
      flex: 1;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }

    &Large {
      flex: 2;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }

    &XLarge {
      flex: 5;
      margin-right: 48px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}