.productGridItem {
	margin: 0.5em;
	border: 1px solid var(--surface-border);
	padding: 2rem;
	height: 100%;
	display: flex;
	flex-direction: column;

	.imgContainer {
		position: relative;
		overflow: hidden;
		width: 95%;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin: 2rem auto;
		opacity: var(--stock-opacity);

		img {
			width: 100%;
		}

		.productDescription {
			margin: 0.3rem 0;
			padding: 0.3rem 1rem;
			border-radius: 0.2rem;
			width: max-content;
			position: absolute;
			top: 0;
			left: -0.3rem;
			font-size: 10px;

			&.customizable {
				background-color: rgba(#3f51b5, 0.3);
				color: var(--primary-color);
				text-shadow: 2px 2px 4px white;
				font-weight: 600;
				border: 1px solid rgba(#3f51b5, 0.3);
			}

			&.notCustomizable {
				background-color: rgba(#bdbdbd, 0.3);
				color: var(--text-color);
				text-shadow: 2px 2px 4px white;
				font-weight: 600;
				border: 1px solid rgba(#bdbdbd, 0.3);
			}
		}
	}

	&Top,
	&Bottom {
		display: flex;
		flex-direction: column;
	}

	&Bottom {
		margin-top: auto;
	}

	&Content {
		text-align: center;
		cursor: pointer;
	}

	.productPrice {
		font-size: 1rem;
		font-weight: 500;
		margin: 1rem 0 0 0;
	}

	.productDiscountPrice {
		font-size: 1rem;
		font-weight: 600;
		color: #3f51b5;
	}

	.productName {
		font-size: 1.5rem;
		font-weight: 700;
	}

	.outOfStock {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		bottom: 1.6rem;

		&Label {
			width: 75%;
			background-color: lightgray;
			color: white;
			padding: 0.25rem 0;
			font-weight: 400;
		}
	}

	.productCategoryIcon {
		vertical-align: middle;
		margin-right: 0.5rem;
	}

	.productCategory {
		font-weight: 600;
		vertical-align: middle;
	}
}
