.printramProMain {
    text-align: center;

    .periodCardMain{
        display: flex;
        padding: 1rem;
        border: 1px solid lightgrey;
        border-radius: .5rem;
        margin: 1rem 0;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .name {
            margin-left: 1rem;
        }

        .priceContainer {
            display: flex;
            flex-direction: column;
            text-align: end;

            .price {
                font-weight: 700;
                font-size: 15px;
            }

            .description {
                font-size: 12px;
                font-weight: 300;
                color: gray
            }
        }
    }
}
