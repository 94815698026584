.dialog {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 26rem;

	.img {
		text-align: center;
		margin: .75rem 0 1.5rem 0;
	}

	.text {
		margin: 1rem 0;
		text-align: center;
		font-weight: 500;
		font-size: 1rem;
	}
}