.trackInfo {
	&Row {
		display: flex;
		border: 1px solid #d1d1d1;
		border-bottom: none;

		&:last-child {
			border-bottom: 1px solid #d1d1d1;
		}
	}

	&Label,
	&Value {
		padding: 0.5rem;
		line-height: 1;
	}

	&Label {
		flex-basis: 45%;
	}

	&Value {
		border-left: 1px solid #d1d1d1;
	}
}