.size {
	&Wrapper {
		border: 1px solid rgba(black, 0.2);
		border-radius: 10px;
		// padding: 1rem;
	}

	&Header {
		display: flex;

		&Item {
			flex: 1;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding-block: 0.8rem;
			border-bottom: 1px solid rgba(black, 0.2);

			&.empty {
				flex: 0 0 var(--i-width, 90px);
				border-right: 1px solid rgba(black, 0.2);
			}
		}
	}

	&Body {
		&Wrapper {
			display: flex;
		}

		&Left {
			display: flex;
			flex-direction: column;
			border-right: 1px solid rgba(black, 0.2);

			&Item {
				padding: 0.8rem 1rem;
				width: max-content;
			}
		}

		&Container {
			display: flex;
			flex-direction: column;
		}

		&Content {
			display: flex;
			width: 100%;

			&Item {
				flex: 1;
				text-align: center;
				padding-block: 0.8rem;
			}
		}
	}
}
