.locTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;

	h6 {
		font-size: 1.2rem;
		font-weight: 700;
		margin-bottom: .25rem;
	}

	span {
		font-size: .9rem;
		color: #6B7280;
	}

	.addBtn {
		border: 1px solid #E5E7EB;
		border-radius: .5rem;
		padding: .5rem 1rem;
		color: #101531;
		font-weight: 500;
		cursor: pointer;
	}
}

.locCard {

	&Name {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap:1rem;
		padding: 1rem;
		border-bottom: 1px solid #E5E7EB;

		&Icon {
			border: 1px solid #E5E7EB;
			border-radius: .5rem;
			padding: .25rem .25rem 0 .25rem;
		}

		&Title {
			flex:1;

			& > span {
				font-size: 1rem;
				font-weight: 600;
			}
		}

		&Actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;

			& > span {
				background-color: #F3F4F6;
				border-radius: .5rem;
				padding: .4rem .5rem;
				font-size: .8rem;
				font-weight: 400;
				color: #555555;
			}

			&Icon {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #E5E7EB;
				border-radius: .5rem;
				background-color: white;
				height: 2rem;
				width: 2rem !important;
				color: #555555;
				cursor: pointer;

				&:hover,
				&:focus,
				&:focus-visible{
					background-color: transparent !important;
					color: #555555 !important;
					border: 1px solid #E5E7EB !important;

				}

				& > :hover {
					background: unset !important;
				}
			}
		}
	}

	&Sales {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap:1rem;
		padding: 1rem;
		border-bottom: 1px solid #E5E7EB;

		&Title {
			flex: 1;

			& > span {
				font-size: .9rem;
				font-weight: 600;
			}

			&Icons {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1rem;
				margin-top: 1rem;

				span {
					font-size: .9rem;
					font-weight: 500;
				}

				.locCardSalesIcon {
					border: 1px solid #E5E7EB;
					border-radius: .5rem;
					padding: .25rem .25rem 0 .25rem;
				}
			}
		}

		&Actions {
			& > span {
				border: 1px solid #E5E7EB;
				border-radius: .5rem;
				padding: .5rem 1rem;
				color: #101531;
				font-size: .9rem;
				font-weight: 400;
				cursor: pointer;
			}
		}
	}

	&Items {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap:1rem;
		padding: 1rem;

		&Title {
			flex: 1;

			& > span {
				font-size: .9rem;
				font-weight: 600;
			}

			&Icons {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1rem;
				margin-top: 1rem;

				span {
					font-size: .9rem;
					font-weight: 500;
				}

				.locCardItemsIcon {
					border: 1px solid #E5E7EB;
					border-radius: .5rem;
					padding: .25rem .25rem 0 .25rem;
				}
			}
		}

		&Actions {
			& > span {
				border: 1px solid #E5E7EB;
				border-radius: .5rem;
				padding: .5rem 1rem;
				color: #101531;
				font-size: .9rem;
				font-weight: 400;
				cursor: pointer;
			}
		}
	}
}

.locMainBody {
    margin-top: 1rem;
    padding: 1.5rem 0 0;
    background-color: #fff;
    border: 1px solid rgba(16, 21, 49, 0.2);
    position: relative;
	padding: 0;
}

.overlayBtns {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: .5rem .75rem;
	gap: .5rem;
	font-size: .85rem;
	font-weight: 400;
	box-shadow: 0 0.625rem 0.938rem 0 rgba(gray, 0.2);
	cursor: pointer;
}