@import 'assets/styles/config/mixin.scss';

.tickets {
	&Table {
		&Wrapper {
			box-shadow: 0 0.2rem 0.5rem rgba($c-black, 0.05);
			border-bottom: 1px solid rgba($c-black, 0.2);
			padding: 0 1.5rem 0 1.5rem;
		}

		&Header {
			display: flex;
			gap: 1.5rem;
			align-items: center;
			height: 4.2rem;

			&Titles {
				flex: 1 1 calc(100% - 19rem);
				display: flex;
				align-items: center;
				gap: 1rem;

				&Code {
					flex: 1 1 20%;
				}

				&From {
					flex: 1 1 20%;
				}

				&Title {
					flex: 1 1 25%;
				}

				&Status {
					flex: 1 1 10%;
				}

				&Date {
					flex: 1 1 25%;
					cursor: pointer;
				}
			}
		}

		&Body {
			position: relative;

			&Wrapper {
				padding: .5rem 0;
				display: flex;
				align-items: center;
				min-height: 5rem;
			}

			&Items {
				flex: 1 1 calc(100% - 20rem);
				display: flex;
				align-items: center;
				gap: 1rem;
			}

			&Item {
				&Wrapper {
					display: block;
					transition: background-color 100ms ease-in-out;
					cursor: pointer;

					&:not(:first-child) {
						border-top: 1px solid rgba($c-black, 0.2);
					}

					&:hover {
						background-color: darken($c-white, 4%);

						& .ordersTableBodyItems {
							h6 {
								color: $c-primary;
							}

							& .ordersTableBodyItemInventory {
								color: $c-primary;
							}
						}
					}

					&.loadMore {
						min-height: 5rem;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: space-evenly;
						gap: 0.5rem;
						cursor: auto;

						&:hover {
							background-color: transparent;
						}
					}
				}

				&Code {
					flex: 1 1 20%;
					display: flex;
					flex-direction: column;
					justify-content: center;

					&Title {
						font-size: 1rem;
					}

					&Desc {
						font-size: .9rem;
						line-height: .9rem;
						margin-top: .5rem;

						span {
							display: block;
						}
					}
				}

				&From {
					flex: 1 1 20%;
					font-size: 0.875rem;
				}

				&Title {
					flex: 1 1 25%;

					h6 {
						max-width: 15rem;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: .25rem;
						font-size: 1rem;
						font-weight: 400;

						&.isWaiting {
							font-weight: 500;
						}
					}
				}

				&Status {
					flex: 1 1 10%;

					& > span {
						border: 1px solid rgba($c-black, 0.2);
						padding: 0.1rem 0.3rem;
						border-radius: 0.2rem;
						background-color: rgba($c-black, 0.03);
						max-width: max-content;
						margin-top: 0.3rem;
						font-size: .9rem;
					}
				}

				&Date {
					flex: 1 1 25%;
					font-size: 0.875rem;
				}
			}
		}
	}
}

.isWaitingStatus {
	& > span {
		font-weight: 700;
		color: $c-black;
		border: 1px solid rgba($c-black, 0.2);
		background-color: rgba(#fbc02d, 0.5);
	}
}

.isWaitingTicket {
	background-color: rgba(yellow, 0.1);
}

.isClosedStatus {
	& > span {
		font-weight: 400;
		color: $c-black;
		border: 1px solid rgba($c-black, 0.2);
		background-color: darken($c-light, 5%);
	}
}

.isClosedTicket {
	background-color: darken($c-light, 5%);
}

.status {
	.filters {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		margin: .5rem 0 1rem 0;

		.filter {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 100px;
			padding: 0.5rem 1rem;
			border-radius: 2px;
			background: var(--surface-0);
			border: 1px solid var(--surface-200);
			background: linear-gradient(to left, var(--surface-0) 50%, var(--surface-100) 50%);
			background-size: 200% 100%;
			background-position: right bottom;
			transition: background-position 0.5s ease-out;
			color: #000;
			cursor: pointer;
			font-weight: 400;
			font-family: inherit;
			line-height: 1;
			box-shadow: 0 0 0 1px var(--surface-100);

			&:hover {
				background-color: transparent;
				background-position: left bottom;
			}

			&:focus {
				box-shadow: 0 0 0 1px var(--green-400);
			}

			&.active {
				border: 1px solid var(--blue-500);
				font-weight: 500;
			}

		}
	}
}