@import 'assets/styles/config/mixin.scss';

.user {
	&Wrapper {
		background-color: rgba($c-black, 0.01);
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		border-radius: 0.6rem;
	}

	&Item {
		padding: 1rem;
		width: 100%;
		cursor: pointer;
		transition: background-color 100ms ease-in-out;
		display: flex;
		gap: 1rem;

		&:first-child {
			margin-top: 0.5rem;
		}

		&:last-child {
			margin-bottom: 0.5rem;
		}

		h6 {
			line-height: 1;
			margin: 0;

			&:first-child {
				flex: 0 1 35%;
			}
		}

		&:nth-of-type(even) {
			background-color: rgba($c-black, 0.03);
		}

		&:hover {
			background-color: rgba($c-black, 0.14);
		}
	}
}

.answer {
	&Wrapper {
		background-color: rgba($c-black, 0.01);
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		border-radius: 0.6rem;
	}

	&Item {
		padding: 1rem;
		width: 100%;
		transition: background-color 100ms ease-in-out;
		display: flex;
		flex-direction: column;
		gap: 1rem;

		&:first-child {
			margin-top: 0.5rem;
		}

		&:last-child {
			margin-bottom: 0.5rem;
		}

		h6 {
			line-height: 1;
			margin: 0;

			&:first-child {
				flex: 0 75%;
			}
		}

		&:nth-of-type(even) {
			background-color: rgba($c-black, 0.03);
		}
	}
}
