@import 'assets/styles/config/mixin.scss';

.product {
	display: flex;
	width: 100%;
	padding-bottom: 1rem;
	border-bottom: 1px solid var(--surface-400);

	.imgDivider {
		width: 100%;
		max-width: 8rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.productItemInfo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 0.5rem;
		width: 100%;
		max-width: 13rem;
		margin-right: 2rem;
	}

	.sizes {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 0.5rem;
		width: 100%;
		text-align: center;

		.sizeItems {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 1rem;

			& > button {
				height: 2.1875rem;
				padding: 0 0.5rem;
				min-width: 2.1875rem;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: transparent;
				border: 1px solid $c-black;
				border-radius: 0.25rem;
				cursor: pointer;
				transition: all 100ms ease-in-out;
				font-size: 1rem;

				&.active {
					border-color: $c-primary;
					color: $c-primary;
					font-weight: 600;
				}
			}
		}
	}
}

.sideTab {
	&Wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 1rem;
		border-bottom: 1px solid var(--surface-400);
		margin: 0 auto;
		margin-top: 1rem;
		overflow-x: auto;
		max-width: 90%;
		scroll-snap-type: x mandatory;
	}

	&Item {
		padding: 0.5rem 1rem;
		cursor: pointer;
		border-bottom: 2px solid transparent;
		transition: border 100ms ease-in-out;
		text-wrap: nowrap;
		scroll-snap-align: start;

		&:hover {
			background-color: $c-light;
		}

		&.selected {
			border-bottom-color: $c-black;
			pointer-events: none;
			font-weight: 600;
		}
	}
}

.sizeInputContainer {
	margin: 2rem auto;

	.inputWrapper {
		display: flex;
		gap: 2rem;

		&Items {
			display: flex;
			gap: 0.5rem;
			margin-top: 0.5rem;
			align-items: center;

			& .inputGroup {
				display: flex;
				align-items: center;
				justify-content: center;

				& > :global(.pr-input-group) {
					& > label {
						display: none;
					}

					& > input {
						max-width: 6rem;
						border-radius: 0.4rem 0 0 0.4rem;
						height: 2rem;
						border-color: #d1d3d4;
					}
				}

				& > span {
					background-color: #d1d3d4;
					height: 2rem;
					line-height: 1;
					border-radius: 0 0.4rem 0.4rem 0;
					padding: 0 0.5rem;
					display: flex;
					align-items: center;
				}
			}
		}
	}
}

.fianlMessage {
	border: 1px solid transparent;
	border-radius: 0.25rem;
	width: 100%;
	padding: 0.6rem 0;

	& > p {
		text-align: center;
		color: $c-light;
		font-size: 0.875rem;
	}

	&.success {
		border-color: darken($c-success, 10%);
		background-color: $c-success;
	}

	&.error {
		border-color: darken($c-red, 10%);
		background-color: $c-red;
	}
}
