@import 'assets/styles/config/mixin.scss';

.etsy {
	&Wrapper {
		position: relative;
	}

	&Header {
		display: flex;
		padding: 0 !important;
		overflow: auto;
		box-shadow: 0 0.2rem 0.5rem rgba($c-black, 0.05);

		&Item {
			padding: 0.8rem 2rem;
			font-weight: 500;
			cursor: pointer;

			&:hover {
				background-color: $c-light;
			}

			&.active {
				background-color: darken($c-light, 3%);
				cursor: auto;
			}
		}
	}

	&Footer {
		padding: 1rem !important;
		box-shadow: 0 -0.2rem 0.5rem rgba($c-black, 0.05);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 1rem;

		& > p {
			margin: 0;
			margin-right: auto;
			display: flex;
			gap: 0.5rem;
			align-items: center;
			color: var(--blue-700);
		}
	}
}

.image {
	&Wrapper {
		display: flex;
		width: 100%;
		gap: 1rem;
		overflow-x: auto;
	}

	&Button {
		min-width: 100px;
		width: 100px;
		border-radius: 10px;
		overflow: hidden;
		cursor: pointer;
		border: 2px solid lighten(grey, 20%);

		& > img {
			width: 100%;
			max-width: 100%;
		}

		&.selected {
			border-color: var(--primary-color);
		}
	}
}

.priceInput {
	max-width: 6rem;
	max-height: 38px;

	& > input {
		max-width: inherit;
	}
}

.syncBtn {
	border: 1px solid rgba($c-black, 0.2);

	.loading {
		-webkit-animation: rotating 1s linear infinite;
		-moz-animation: rotating 1s linear infinite;
		-ms-animation: rotating 1s linear infinite;
		-o-animation: rotating 1s linear infinite;
		animation: rotating 1s linear infinite;
	}
}

/* Safari and Chrome */
@-webkit-keyframes rotating {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.revenue {
	height: 63px;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;

	&Stabil {
		background-color: $c-white;
		color: $c-black;
	}

	&Danger {
		background-color: lighten(red, 40%);
		color: darken(red, 10%);
	}

	&Success {
		background-color: lighten(#8eac50, 40%);
		color: darken(#8eac50, 10%);
	}
}
