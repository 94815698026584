@import 'assets/styles/config/mixin.scss';

.divider {
	height: 1px;
	background-color: var(--surface-400);
}

.addProduct {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	padding: 2rem;
	border: 1px dashed var(--surface-400);
	border-radius: 0.25rem;
	margin-top: 2rem;
	color: $c-success;
	cursor: pointer;
	transition: background-color 100ms ease-in-out;

	&:hover {
		background-color: darken($c-white, 3%);
	}

	& > h5 {
		margin: 0;
	}
}
