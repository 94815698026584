@import 'assets/styles/config/mixin.scss';

.dialog {
	--footer-height: 4rem;

	&:global(.p-dialog .p-dialog-header .p-dialog-title) {
		font-size: 1.5rem;
	}

	:global(.pi.pi-times) {
		font-size: 1.25rem;
	}

	&:global(.p-dialog.p-component) {
		width: 100%;
		height: 100%;
	}

	&:global(.p-dialog) {
		max-height: 100% !important;
	}

	.wrapper {
		--side-width: 25rem;

		display: flex;
		width: 100%;
		height: calc(100% - var(--footer-height));
		border-top: 1px solid var(--surface-400);
		position: relative;

		.info,
		.container {
			padding: 1rem;
		}

		.info {
			border-right: 1px solid var(--surface-400);
			width: var(--side-width);
			overflow-y: auto;

			h5 {
				font-size: 1.125rem;
				margin: 0;

				& > span {
					display: inline-block;
					width: 70%;
					padding-bottom: 6px;
					border-bottom: 3px solid $c-primary;
				}
			}

			& > .productInfo {
				margin-top: 1rem;

				.color {
					&Wrapper {
						display: flex;
						flex-wrap: wrap;
						gap: 0.75rem;
						margin-bottom: 1.5rem;
					}

					&Item {
						width: 2rem;
						height: 2rem;
						border-radius: 0.25rem;
						background-color: var(--color-item-bg-color);
						background-position: center;
						background-size: cover;
						background-repeat: no-repeat;
						border: 0.4px solid #d9d9d9;
					}
				}

				.size {
					&Wrapper {
						display: flex;
						flex-wrap: wrap;
						gap: 0.75rem;
						margin-bottom: 1.5rem;
					}

					&Item {
						min-width: 2.1875rem;
						height: 2.1875rem;
						border-radius: 0.25rem;
						border: 1px solid $c-black;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				.side {
					&Wrapper {
						display: flex;
						flex-direction: column;
					}

					&Item {
						height: 2.1875rem;
						display: flex;
						align-items: center;
						justify-content: space-between;
						border-bottom: 1px solid var(--surface-400);

						&:last-child {
							border-bottom: none;
						}
					}
				}
			}
		}

		.container {
			width: calc(100% - var(--side-width));

			& .filterDropdown {
				width: 100%;

				& > button {
					height: 100%;
				}
			}

			.clearFilterBtn {
				height: auto;
				width: 20rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.product {
			&Wrapper {
				max-height: calc(100% - var(--footer-height) * 2);
				overflow-y: auto;
				display: flex;
				flex-direction: column;
			}

			&Item {
				display: flex;
				align-items: center;
				border-bottom: 1px solid var(--surface-400);
				padding: 0.5rem 1rem;
				min-height: 8rem;
				cursor: pointer;
				user-select: none;

				&:last-child {
					border-bottom: none;
				}

				.imgDivider {
					width: 8rem;
					display: flex;
					justify-content: center;

					& > img {
						display: block;
						max-width: 5rem;
						height: 5rem;
						object-fit: contain;
						border: 1px solid darken($c-light, 8%);
						border-radius: 0.2rem;
					}
				}

				&:hover {
					background-color: $c-light;
					color: $c-primary;
				}

				&.active {
					background-color: darken($c-light, 5%);
					color: $c-primary;
				}

				&Info {
					display: flex;
					flex-direction: column;
					gap: 0.2rem;
				}

				&.loading {
					opacity: 0.7;
					pointer-events: none;
				}
			}
		}
	}

	.footer {
		height: calc(var(--footer-height) + 1.25rem);
		position: fixed;
		bottom: 0;
		left: 1.25rem;
		right: 1.25rem;
		padding: 0 1.25rem;
		border-top: 1px solid var(--surface-400);
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
