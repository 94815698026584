@import 'assets/styles/config/variables.scss';

.detail {
	&Wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 0.5rem;

		&.scrollable {
			display: grid;
			grid-template-columns: repeat(auto-fill, 16.594rem);
			grid-auto-flow: column;
			grid-auto-columns: 16.594rem;
			overflow-x: hidden;
			user-select: none;
			scroll-snap-type: x mandatory;

			.detailItem {
				cursor: pointer;
			}
		}
	}

	&Item {
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);

		& * {
			margin: 0;
			padding: 0;
		}

		&Header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			height: 2rem;
		}
	}
}

.addProduct {
	&Wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: min(40rem, 95%);
		margin: 0 auto;
		border: 1px dashed $c-primary;
		color: $c-primary;
		background-color: rgba($c-primary, 0.01);
		border-radius: 0.2rem;
		padding: 0.5rem 0.75rem;
		cursor: pointer;
		transition: all 100ms ease-in-out;

		&:hover {
			background-color: rgba($c-primary, 0.05);
		}
	}

	&Text {
		display: flex;
		align-items: center;
		gap: 0.3rem;
	}
}

.productList {
	&VariantWrapper {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		border-top: 1px solid rgba($c-black, 0.2);
		padding: 1rem;
	}

	&Actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1rem;

		& > span {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.5rem;
		}
	}

	&Header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.5rem 1rem;

		h2 {
			font-size: 1.3rem;
			margin: 0;
		}
	}

	&Variant {
		display: flex;
		gap: 1rem;

		.imageSide {
			max-width: 10rem;
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: center;

			& > img {
				max-width: 8rem;
				border: 1px solid rgba($c-black, 0.2);
				width: 100%;
				border-radius: 0.2rem;
			}
		}

		.optionSide {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			width: 60%;

			& > p {
				margin: 0;
				padding: 0;
			}

			&VariantWrapper {
				display: flex;
				flex-wrap: wrap;
				gap: 1rem;
				font-size: 0.95rem;

				& > span {
					color: var(--text-color-secondary);
				}
			}

			&PriceWrapper {
				display: flex;
				gap: 1rem;
				align-items: center;

				& > input {
					max-width: 5rem;
				}
			}
		}

		.fileSide {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			width: 100%;
		}
	}
}

.tickets {
	display: flex;
	gap: 0.5rem;

	&Header {
		margin: 0 0 1.2rem 0;
		display: flex;
		justify-content: space-between;
	}

	&Card {
		flex: 1;
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);
		display: flex;
		flex-direction: column;
	}

	&LastComment {
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);
		display: flex;
		flex-direction: column;
	}

	&Status {
		margin: 0 0 .5rem 0;
		& > span {
			border: 1px solid rgba($c-black, 0.2);
			padding: 0.1rem 0.3rem;
			border-radius: 0.2rem;
			background-color: rgba($c-black, 0.03);
			max-width: max-content;
			font-size: .9rem;
		}
	}

	.isWaitingStatus {
		& > span {
			font-weight: 700;
			color: $c-black;
			border: 1px solid rgba($c-black, 0.2);
			background-color: rgba(#fbc02d, 0.5);
		}
	}

	.isClosedStatus {
		& > span {
			font-weight: 400;
			color: $c-black;
			border: 1px solid rgba($c-black, 0.2);
			background-color: darken($c-light, 5%);
		}
	}
}

.headerTitles {
	h6 {
		margin:0;
		font-size: 1rem;
		font-weight: 500;
		color: gray;
	}
}
