@import "./variables.scss";

@mixin mq($width: "mobile") {
  @if ($width == "mobile") {
    // Mobile devices (768px dowm)
    @media (max-width: 767px) {
      @content;
    }
  } @else if ($width == "tablet") {
    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
      @content;
    }
  } @else if ($width == "desktop") {
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      @content;
    }
  } @else if ($width == "large") {
    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
      @content;
    }
  } @else if ($width == "xx-large") {
    // Extra large devices (xx large screens, 1400px and up)
    @media (min-width: 1440px) {
      @content;
    }
  }
}