@import 'assets/styles/config/variables.scss';

.header {
	display: none;
}

.orderContainer {
	position: relative;

	.loading {
		position: absolute;
		inset: 0;
		background-color: rgba($c-black, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;

		.p-progress-color {
			svg circle {
				stroke: var(--primary-color-text) !important;
			}
		}
	}
}