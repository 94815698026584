@import 'assets/styles/config/mixin.scss';

.stepWrapper {
	padding: 1rem !important;
	position: static !important;

	& > h4,
	& > h5 {
		margin: 0;

		& > span {
			display: inline-flex;
			width: 2rem;
			height: 2rem;
			font-size: 1.25rem;
			border: 1px solid rgba($c-black, 0.3);
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			margin-right: 0.5rem;
		}
	}

	& > h5 {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
	}

	&.disabled {
		pointer-events: none;
		user-select: none;

		& > h4,
		& > h5 {
			color: rgba($c-black, 0.3);
		}
	}

	&.active {
		& > h4,
		& > h5 {
			& > span {
				border-color: $c-primary;
				background-color: $c-primary;
				color: $c-light;
			}
		}
	}

	&.completed {
		& > h4,
		& > h5 {
			color: $c-success;

			& > span {
				border-color: $c-success;
				background-color: $c-success;
				color: $c-light;
			}
		}
	}

	&.secondStep {
		.reason {
			&Wrapper {
				display: flex;
				flex-direction: column;
				align-items: self-start;
				gap: 0.5rem;
				margin-top: 1rem;

				&Completed {
					& > li {
						color: $c-success;
						margin-left: 1.5rem;
					}
				}
			}

			&Item {
				font-size: 1rem;
				background-color: $c-bg-grey;
				padding-left: 1rem;
				height: 2.25rem;
				display: inline-flex;
				border-radius: 0.25rem;
				align-items: center;

				& > .itemCloseIconWrapper {
					margin-left: 1rem;
					width: 2rem;
					height: 100%;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					height: 100%;
					border-radius: 0 0.25rem 0.25rem 0;

					&:hover,
					&:focus,
					&:focus-within,
					&:focus-visible,
					&:active {
						background-color: darken($c-bg-grey, 5%);
					}
				}
			}
		}

		.table {
			text-align: left;
			border-collapse: collapse;
			width: 100%;

			th,
			td {
				border-bottom: 1px solid var(--surface-400);
				padding: 1.5rem 0;
			}

			tr:last-child {
				& > td {
					border-bottom: none;
				}
			}

			th {
				text-transform: uppercase;
				color: var(--surface-400);
			}

			th.action {
				color: $c-black;
				text-align: center;
			}

			td {
				vertical-align: top;
			}

			tr.info {
				th,
				td {
					border-bottom: none;
					padding-bottom: 0rem;
				}

				.old,
				.new {
					display: inline-flex;
					width: 3.5625rem;
					height: 2rem;
					font-size: 1rem;
					border-radius: 0.25rem;
					font-weight: 600;
					align-items: center;
					justify-content: center;
				}

				.old {
					color: $c-black;
					border: 1px solid $c-black;
				}

				.new {
					color: $c-white;
					border-color: $c-success;
					background-color: $c-success;
				}
			}

			.productInfo {
				display: flex;
				gap: 1rem;

				&Container {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;

					h6 {
						margin: 0;
					}

					& > span {
						font-size: 0.875rem;
						color: #555;
					}

					&Color {
						display: flex;
						align-items: center;
						gap: 0.5rem;

						& > span {
							display: inline-block;
							width: 0.8125rem;
							height: 0.8125rem;
							border: 1px solid #d1d3d4;
							border-radius: 0.25rem;
						}
					}
				}
			}

			.printFile {
				&Wrapper {
					display: grid;
					gap: 1rem;
					grid-template-columns: 7.8125rem 7.8125rem;
				}

				&Item {
					border: 1px solid var(--surface-400);
					width: 7.8125rem;

					&ImgContainer {
						height: 8.875rem;
						display: flex;
						align-items: center;
						justify-content: center;
						border-bottom: 1px solid var(--surface-400);

						& > img {
							max-width: 100%;
							max-height: 100%;
							object-fit: contain;
							object-position: center;
						}
					}

					&Info {
						padding: 0.75rem;
					}
				}
			}
		}
	}

	&.thirdStep {

		.printFile {
			&Wrapper {
				display: flex;
				max-width: 30rem;
				border: 2px solid var(--surface-400);
				border-radius: 0.5rem;
				padding: 0.3rem;
				transition: background-color 100ms ease-in-out;
				position: relative;

				&:hover {
					background-color: $c-light;
				}
			}

			&Btn {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				align-items: center;
				padding: 0.5rem 2rem;
				flex-shrink: 0;

				& > span {
					font-size: 0.875rem;
					font-weight: 600;
				}
			}

			&Divider {
				width: 0.125rem;
				min-height: 100%;
				margin: 0 1rem 0 0;
				background-color: var(--surface-400);
			}

			&Info {
				display: flex;
				flex-direction: column;
				text-align: center;
				align-items: center;
				justify-content: center;
				padding-right: 0.7rem;


				.printTitle {
					font-size: 0.875rem;
					font-weight: 600;
				}

				.printAccepts {
					font-size: 0.75rem;
					font-weight: 600;
					color: var(--surface-400);
				}
			}
		}
	}

	&.fourthStep {
		.file {
			&Wrapper {
				position: relative;
				border: 2px solid $c-border;
				border-radius: 0.5rem;
				width: 14rem;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				padding: 1rem 0;
				color: $c-border;
			}

			&Input {
				position: absolute;
				display: inline-block;
				opacity: 0;
				width: 100%;
				height: 100%;
				cursor: pointer;
				user-select: none;
			}
		}
	}
}
