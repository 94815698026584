@import 'assets/styles/config/mixin.scss';

.table {
	text-align: left;
	border-collapse: collapse;
	width: 100%;

	th {
		padding: 0.8rem 0 1.2rem 0;
	}

	td {
		border-top: 1px solid var(--surface-400);
		padding: 0.8rem 0 0.8rem 0;
	}

	tr:last-child {
		& > td {
			border-bottom: none;
		}
	}

	th {
		text-transform: uppercase;
		color: var(--surface-400);
	}

	td {
		vertical-align: top;
	}

	tr {
		&.etsyInfoWrapper {
			transform-origin: top;
			transition: height 250ms ease-in-out, opacity 100ms ease-in-out;
			display: inline-block;
			opacity: 0;
			height: 0;

			td {
				border-top: none;
				padding: 0;
			}

			.etsyDetailV2 {
				border: 1px solid #d1d3d4;
				border-radius: 0.25rem;
				padding: 1rem;
				margin-bottom: 1rem;
				overflow: hidden;

				div {
					margin: .25rem 0;
				}
			}

				&.active {
					opacity: 1;
					height: 8rem;
					margin-bottom: 1rem;
			}
		}


	}

	.productInfo {
		position: relative;
		display: flex;
		flex-direction: column;

		h6 {
			font-size: 1.1rem;
		}

		div {
			margin: 0.5rem 0 0 0;
		}

		.etsyDetailButton {
			display: inline-flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			border: 1px solid #d1d3d4;
			border-radius: 1.5rem;
			padding: 0.5rem 0.75rem 0.5rem 1rem;
			background-color: #f6f6f6;
			cursor: pointer;
		}

		.hexCode {
			height: 1.25rem;
			width: 1.25rem;
			display: inline-block;
			border: 1px solid #d1d3d4;
			border-radius: .25rem;
			margin: 0;
		}

		.color {

			display: flex;
			align-items: center;
			gap: 0.5rem;

			& > span {
				display: inline-block;
				width: 0.8125rem;
				height: 0.8125rem;
				border: 1px solid #d1d3d4;
				border-radius: 0.25rem;
			}

		}
	}

	.etsyDetail {
		position: absolute;
		top: calc(100% + 0.5rem);
		left: 0;
		border: 1px solid #d1d3d4;
		border-radius: 0.25rem;
		padding: 1rem;
		margin-bottom: 1rem;
		max-width: 21rem;
		height: 8rem;
		overflow: hidden;

		div {
			margin: 0.25rem 0;
		}
	}

	.printFile {
		&Wrapper {
			display: grid;
			gap: 1rem;
			grid-template-columns: 7.8125rem 7.8125rem;
		}

		&Item {
			border: 1px solid var(--surface-400);
			width: 7.8125rem;

			&ImgContainer {
				height: 8.875rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border-bottom: 1px solid var(--surface-400);

				& > img {
					max-width: 100%;
					max-height: 100%;
					object-fit: contain;
					object-position: center;
				}
			}

			&Info {
				padding: 0.75rem;
			}
		}

		&EmptyContainer {
			display: grid;
			gap: 1rem;
			grid-template-columns: 7.8125rem 7.8125rem;

			.printFileEmpty {
				border: 2px dotted var(--surface-400);
				width: 7.8125rem;
				height: 8.875rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.8rem;
				padding: 1rem;
				color: #b4b4b4;
			}
		}
	}

	.buttons {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
	}
}