@import 'assets/styles/config/mixin.scss';

.switch {
	background-color: rgba($c-black, 0.15);
	width: 3.125rem;
	height: 1.75rem;
	border-radius: 1.875rem;
	border: none;
	cursor: pointer;
	display: inline-block;
	position: relative;
	transition: background-color 150ms ease-in-out;

	& > span {
		position: absolute;
		display: inline-block;
		width: 1.5rem;
		height: 1.5rem;
		background-color: $c-white;
		top: calc(0.25rem / 2);
		left: calc(0.25rem / 2);
		border-radius: 50%;
		transition: left 150ms ease-in-out;
	}

	&.active {
		background-color: $c-primary;

		& > span {
			left: calc(100% - 1.5rem - calc(0.25rem / 2));
		}
	}
}
