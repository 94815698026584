@import 'assets/styles/config/mixin.scss';

.balanceMain {

	.balanceCard {
		display: flex;
		flex-direction: column;
		padding: 1rem;
		background-color: #f7f7f7;
		gap: 1rem;

		@include mq('tablet') {
			flex-direction: row;
			align-items: center;
		}

		&Descriptions {
			flex: 2;

			.description {
				margin: 1rem 0;

				span {
					margin-left: .5rem;
				}
			}
		}

		&Price {
			flex: 1;
			display: flex;
			flex-direction: column;

			.price {
				padding: .75rem;
				background-color: $c-white;
				font-size: 1.2rem;
				font-weight: 700;
			}

			.btn {
				display: flex;
				justify-content: flex-end;
				margin: .5rem 0 0 0;
			}
		}
	}
}