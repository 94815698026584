@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';
@import './assets/styles/pr-ui-kit.scss';

html {
	font-size: 16px !important;
}

*,
*::before,
*::after {
	scroll-behavior: smooth;
}

span,
p {
	white-space: pre-wrap;
}

.default-avatar {
	width: 2.286rem;
	height: 2.286rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 1.5rem;
	font-weight: 500;
	outline: 1px solid rgba($c-light, 0.5);
}

.p-dialog.p-component {
	width: min(70rem, 95%);

	&.delete-confirm-dialog {
		width: min(40rem, 95%);
	}
}

.p-highlight {
	background-color: var(--blue-600) !important;
	color: var(--primary-color-text) !important;
}

.media-type {
	display: flex;
	width: min(90%, 30rem);

	& > * {
		flex: 1;
	}
}

.color-picker {
	width: 2rem;
	height: 2rem;
	border: 1px solid lightgray;
	border-radius: 1rem;
}

.max-w-60 {
	max-width: 60rem;
}

.p-card .p-card-title {
	font-size: 1.2rem;
	margin-bottom: 0.3rem;
}

.banner-mode {
	padding: 0.7rem 2rem;
	font-weight: 500;
	font-size: 1.1rem;

	b {
		text-transform: capitalize;
		text-decoration: underline;
	}

	&.test {
		color: var(--yellow-900);
		background-color: var(--yellow-400);
	}

	&.prod {
		color: var(--green-900);
		background-color: var(--green-400);
	}
}

.p-chips {
	& > ul {
		width: 100%;
	}
}

.ck.ck-editor {
	width: 100%;
}

.ck-editor__editable_inline {
	min-height: 10rem;
}

.editor-output {
	white-space: pre-line;
	* {
		max-width: 100% !important;
		width: auto !important;
	}
}

.custom-loading {
	position: absolute;
	inset: 0;
	background-color: rgba($c-black, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	z-index: 99;

	.p-progress-color {
		svg circle {
			stroke: var(--primary-color-text) !important;
		}
	}
}

.data-table-stock {
	.failed {
		background: rgba(#ec0b0b, 0.1) !important;
	}
}

.odd {
	padding: 1rem 0.5rem;
	border-radius: var(--border-radius);

	&:nth-of-type(even) {
		background-color: rgba($c-black, 0.03);
	}
}

.move-btn {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;

	&:hover {
		background-color: darken($c-light, 3%);
	}
}

.introjs-dontShowAgain {
	margin-bottom: .75rem;
}

.introjs-tooltip {
	max-width: none;

	&text {
		padding: .75rem 1.25rem;
	}

	&buttons {
		a {
			&.introjs-nextbutton, &.introjs-donebutton {
				background-color: #4541FF;
				color:white !important;
				padding: .5rem .75rem;
				text-shadow: none;
				border: 1px solid #B4B4B4;
				border-radius: .5rem;
				cursor: pointer;
			}:hover {
				background-color: #4541FF;
				color:white;
			}

			&.introjs-prevbutton {
				background-color: #F2F2F2;
				padding: .5rem .75rem;
				text-shadow: none;
				border-radius: .5rem;
				border: 1px solid #B4B4B4;
				cursor: pointer;
			}:hover {
				background-color: #F2F2F2;
			}
		}:focus-visible {
			outline: none;
		}

	}
}


/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba($c-black, 0.08);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba($c-black, 0.4);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba($c-black, 0.5);
}

tr.isDisable {
	opacity: 0.5;
}

.p-dropdown-items-wrapper {
	&,
	&::after,
	&::before {
		scroll-behavior: auto !important;
	}
}

.pac-container.pac-logo {
	z-index: 999999;
}

.p-tabview {
	.p-tabview-panels,
	.p-tabview-nav,
	.p-tabview-nav li .p-tabview-nav-link,
	.p-tabview-nav li.p-highlight .p-tabview-nav-link,
	.p-unselectable-text.p-tabview-selected.p-highlight {
		background: transparent !important;
	}

	.p-tabview-nav li.p-highlight .p-tabview-nav-link {
		color: #1565c0 !important;
	}

	.p-tabview-nav li.p-tabview-ink-bar {
		background-color: #1565c0 !important;
	}

	.p-tabview-panels {
		padding: 0;
	}
}

.p-accordion-tab.p-accordion-tab-active {
	margin: 0 !important;
}

.p-toggleable-content.catalog-accordion-tab > .p-accordion-content {
	padding: 0;
	margin: 0;
}

.p-accordion-header.p-highlight.catalog-accordion-tab {
	background-color: transparent !important;
}

.p-accordion .p-accordion-tab:first-child {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}
.p-accordion .p-accordion-tab:last-child {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.p-confirm-dialog-message {
	width: 100%;
}

.unsupported-browser {
	width: 100%;
	min-height: 5rem;
	display: flex;
	padding: 1rem 2rem;
	justify-content: center;
	flex-direction: column;
	background: linear-gradient(to left, #101531, #c96909, #101531);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;

	h4 {
		margin: 0;
		font-size: 1.2rem;
		color: #f7f7f7;
	}

	p {
		margin: 0;
		color: #f7f7f7;
	}
}

.etsy-connection-lost {
	width: 100%;
	min-height: 5rem;
	display: flex;
	padding: 1rem 2rem;
	align-items: center;
	flex-direction: row;
	background-color: #FCE7B8;
	color: #BD7417;

	div {
		flex: 1;
		margin-left: 1rem;

		h5 {
			margin: 0;
			color: #BD7417;
			font-weight: 400;
		}

		p {
			color: #BD7417;
			margin: 0;
		}
	}

	.reconnect-btn {
		font-weight: 500;
		color: whitesmoke;
		padding: .5rem 1rem;
		border-radius: .25rem;
		margin-left: 1rem;
		background-color: #BD7417;
	}

	.check-btn {
		font-weight: 500;
		color: #BD7417;
		padding: .5rem 1rem;
		border: 1px solid #BD7417;
		border-radius: .25rem;
		margin-left: 1rem;
		background: none;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
