@import 'assets/styles/config/variables.scss';

.detail {
	&Wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 0.5rem;

		&.scrollable {
			display: grid;
			grid-template-columns: repeat(auto-fill, 16.594rem);
			grid-auto-flow: column;
			grid-auto-columns: 16.594rem;
			overflow-x: hidden;
			user-select: none;
			scroll-snap-type: x mandatory;

			.detailItem {
				cursor: pointer;
			}
		}
	}

	&Item {
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);

		& * {
			margin: 0;
			padding: 0;
		}

		&Header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			height: 2rem;
		}
	}
}

.table {
	text-align: left;
	border-collapse: collapse;
	width: 100%;

	th,
	td {
		border-bottom: 1px solid var(--surface-400);
		padding: 1.5rem 0;
	}

	tr:last-child {
		& > td {
			border-bottom: none;
		}
	}

	th {
		text-transform: uppercase;
		color: var(--surface-400);
	}

	th.action {
		color: $c-black;
		text-align: center;
	}

	td {
		vertical-align: top;
	}

	tr.info {
		th,
		td {
			border-bottom: none;
			padding-bottom: 0rem;
		}

		.old,
		.new {
			display: inline-flex;
			padding: 0 1rem;
			height: 2rem;
			font-size: 1rem;
			border-radius: 0.25rem;
			font-weight: 600;
			align-items: center;
			justify-content: center;
		}

		.old {
			color: $c-black;
			border: 1px solid $c-black;
		}

		.new {
			color: $c-white;
			border-color: $c-success;
			background-color: $c-success;
		}
	}

	.productInfo {
		display: flex;
		gap: 1rem;

		&Container {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			h6 {
				margin: 0;
			}

			& > span {
				font-size: 0.875rem;
				color: #555;
			}

			&Color {
				display: flex;
				align-items: center;
				gap: 0.5rem;

				& > span {
					display: inline-block;
					width: 0.8125rem;
					height: 0.8125rem;
					border: 1px solid #d1d3d4;
					border-radius: 0.25rem;
				}
			}
		}
	}

	.printFile {
		&Wrapper {
			display: grid;
			gap: 1rem;
			grid-template-columns: 7.8125rem 7.8125rem;
		}

		&Item {
			border: 1px solid var(--surface-400);
			width: 7.8125rem;

			&ImgContainer {
				height: 8.875rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border-bottom: 1px solid var(--surface-400);

				& > img {
					max-width: 100%;
					max-height: 100%;
					object-fit: contain;
					object-position: center;
				}
			}

			&Info {
				padding: 0.75rem;
			}
		}
	}
}
