@import 'assets/styles/config/mixin.scss';

.ro {
	&Step {
		&Wrapper {
			--number-size: 2.5rem;

			position: relative;
			margin: 3rem auto;
			width: min(100%, 45rem);
			display: flex;
			gap: 1rem;
			align-items: center;
			justify-content: space-between;
		}

		&Number {
			width: var(--number-size);
			height: var(--number-size);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border-radius: calc(var(--number-size) / 2);
			border: 2px solid var(--surface-400);
			margin-right: 1rem;
			font-size: 1.25rem;
			color: var(--surface-400);
		}

		&Item {
			font-size: 1.25rem;
			color: var(--surface-400);
			cursor: pointer;
			background-color: #f7f7f7;
			padding: 0 1rem;
			cursor: default;
			pointer-events: none;

			&.active {
				color: $c-black;

				& > .roStepNumber {
					background-color: $c-primary;
					border-color: $c-primary;
					color: $c-white;
					font-weight: 400;
				}
			}

			&.completed {
				color: $c-black;
				cursor: pointer;
				pointer-events: all;

				& > .roStepNumber {
					background-color: $c-black;
					border-color: $c-black;
					color: $c-white;
					font-weight: 400;
				}
			}
		}

		&Divider {
			position: absolute;
			background-color: var(--surface-400);
			height: 0.125rem;
			width: 100%;
			z-index: -1;
		}
	}
}
