@import 'assets/styles/config/mixin.scss';

.areaDialog {
	& :global(.p-dialog-header) {
		border-bottom: 1px solid var(--surface-400);
	}

	& :global(.p-dialog-content) {
		padding: 0;
	}

	&:global(.p-dialog .p-dialog-header .p-dialog-title) {
		font-size: 1.5rem;
	}

	.wrapper {
		--side-width: 23rem;
		--wrapper-height: 35rem;

		display: flex;
		width: 100%;
		height: var(--wrapper-height);

		.categories {
			border-right: 1px solid var(--surface-400);
			width: var(--side-width);
			overflow-y: auto;

			& :global(.p-accordion-tab) {
				border-bottom: 1px solid var(--surface-300);
				box-shadow: none;
			}

			.accordion {
				&Tab {
					& * {
						padding: 0;
						margin: 0;
					}
				}

				&SubCategory {
					&Wrapper {
						display: flex;
						flex-direction: column;
					}

					&Item {
						display: block;
						padding: 1rem 2.5rem;
						line-height: 1;
						background-color: var(--bg-white);
						border: none;
						text-align: left;
						font-size: 1rem;

						&:first-child {
							border-top: 1px solid var(--surface-300);
						}

						&:hover {
							background-color: var(--surface-hover);
							cursor: pointer;
						}

						&:disabled {
							pointer-events: none;
							opacity: 0.5;
						}

						&.active {
							color: $c-primary;
							font-weight: 600;
						}
					}
				}
			}
		}

		.container {
			width: calc(100% - var(--side-width));
			padding: 1rem;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			position: relative;
		}
	}
}
