@import 'assets/styles/config/mixin.scss';

.filterDropdown {
	width: 100% !important;

	& > button {
		height: 2.5rem;
	}
}

.clearFilterBtn {
	height: auto;
	width: 15rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product {
	&Wrapper {
		max-height: calc(var(--wrapper-height) - 10.5rem);
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&Item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--surface-400);
		padding: 0.5rem 1rem;
		min-height: 8rem;
		cursor: pointer;
		user-select: none;

		&:last-child {
			border-bottom: none;
		}

		.imgDivider {
			width: 8rem;
			display: flex;
			justify-content: center;

			& > img {
				display: block;
				max-width: 5rem;
				height: 5rem;
				object-fit: contain;
				border: 1px solid darken($c-light, 8%);
				border-radius: 0.2rem;
			}
		}

		&:hover {
			background-color: $c-light;

			span:first-child {
				color: $c-primary;
			}
		}

		&Info {
			display: flex;
			flex-direction: column;
			gap: 0.2rem;
		}
	}
}
