
.description {
	max-width: 14rem;

	&>p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}
}

.filters {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}