$c-black: #101531;
$c-white: #fff;
$c-light: #f7f7f7;
$c-grey: #f2f2f2;
$c-red: #c21010;
$c-primary: #4541ff;
$c-success: #008a00;
$c-warning: #FF8911;
$c-bg-grey: #F6F6F6;
$c-border: #D1D3D4;

.text-red {
	color: $c-red !important;
}
