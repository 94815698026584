@import 'assets/styles/config/variables.scss';

.detail {
	&Wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 0.5rem;

		&.scrollable {
			display: grid;
			grid-template-columns: repeat(auto-fill, 16.594rem);
			grid-auto-flow: column;
			grid-auto-columns: 16.594rem;
			overflow-x: hidden;
			user-select: none;
			scroll-snap-type: x mandatory;

			.detailItem {
				cursor: pointer;
			}
		}
	}

	&Item {
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);

		& * {
			margin: 0;
			padding: 0;
		}

		&Header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			height: 2rem;
		}
	}
}

.seller {
	&Details {
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);
		margin-bottom: 0.5rem;
	}

	&Info {
		margin-top: .5rem;
		display: flex;
		flex-direction: row;

		& > span:first-child {
			font-weight: 700;
		}
	}
}

.tickets {
	display: flex;
	gap: 0.5rem;

	&Header {
		margin: 0 0 1.2rem 0;
		display: flex;
		justify-content: space-between;
	}

	&Card {
		flex: 1;
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);
		display: flex;
		flex-direction: column;
	}

	&LastComment {
		background-color: lighten($c-light, 1%);
		padding: 0.5rem;
		border-radius: 0.2rem;
		border: 1px solid darken($c-light, 5%);
		display: flex;
		flex-direction: column;
	}

	&Status {
		margin: 0 0 .5rem 0;
		& > span {
			border: 1px solid rgba($c-black, 0.2);
			padding: 0.1rem 0.3rem;
			border-radius: 0.2rem;
			background-color: rgba($c-black, 0.03);
			max-width: max-content;
			font-size: .9rem;
		}
	}

	.isWaitingStatus {
		& > span {
			font-weight: 700;
			color: $c-black;
			border: 1px solid rgba($c-black, 0.2);
			background-color: rgba(#fbc02d, 0.5);
		}
	}

	.isClosedStatus {
		& > span {
			font-weight: 400;
			color: $c-black;
			border: 1px solid rgba($c-black, 0.2);
			background-color: darken($c-light, 5%);
		}
	}
}

.headerTitles {
	h6 {
		margin:0;
		font-size: 1rem;
		font-weight: 500;
		color: gray;
	}
}