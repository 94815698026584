.variantWrapper {
	width: 100%;
	border: 0.2rem solid #f6f6fb;
	margin: 4rem 1rem 0 1rem;
	border-radius: 1.2rem;
	overflow: hidden;

	.variantHeader {
		display: flex;
		background-color: #f6f6fb;
		padding: 1rem 1.2rem;
		font-weight: 500;

		&Title {
			flex: 1;
		}
	}

	.variantBody {
		user-select: none;

		.variantItemHeader {
			display: flex;
			align-items: center;
			padding: 1rem 1.2rem;
			background-color: white;
			overflow: hidden;
			z-index: 3;
			position: relative;

			&Title {
				flex: 1;

				.original {
					flex: 1;
				}

				.discounted {
					flex: 1;
					font-weight: 400;
					color: #4e5fbb;
				}

				.colorName {
					margin-left: 0.3rem;
				}

				.colorHex {
					height: 1.1rem;
					width: 1.1rem;
					border: 1px solid black;
					background-color: var(--bg-color-box);
					border-radius: 1rem;
				}
			}

			&Arrow {
				& > span {
					cursor: pointer;
					transition: transform 150ms ease-in-out;
					padding: .5rem;

					&.opened {
						transform: rotate(180deg);
					}
				}
			}
		}

		.variantItem {
			border: 0.1rem solid #f6f6fb;
			background-color: #f6f6fb;
			padding: 0 1.2rem;
			max-height: 0;
			position: relative;
			transition: max-height 250ms ease-in-out;
			z-index: 2;

			&Body {
				display: flex;
				align-items: center;
				padding: 0.3rem 0;
			}

			&HeaderTitle {
				flex: 1;
			}

			&.activeItem {
				max-height: max-content;
				transition: max-height 250ms ease-in-out;
			}
		}
	}
}

.price {
	display: flex;
	flex-direction: column;
}

.color {
	display: flex;
	flex-direction: row;
}
