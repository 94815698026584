@import 'src/assets/styles/config/variables.scss';
@import 'src/assets/styles/config/mixin.scss';

$font-size: 1rem;
.pageWrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	.resetPasswordWrapper {
		margin-top: 25vh;
		padding: 4rem 2rem;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		align-items: center;

		@include mq('mobile') {
			width: 90%;
		}
		@include mq('tablet') {
			width: 65%;
		}
		@include mq('desktop') {
			width: 50%;
		}
		@include mq('large') {
			width: 40%;
		}

		.formColumn {
			gap: 1rem;
			display: flex;
			flex-direction: column;

			input,
			label,
			Button {
				width: 100%;
			}

			input {
				font-size: $font-size;
			}
			.infoText {
				text-align: left;
				width: 100%;
			}
		}
	}
}
