.Wizard {

    .p-inputtext,
    .p-dropdown {
        border-radius: 10px;
    }

    .custom_grid {
        display: grid;
        gap: 10px;
        width: 100%;
        align-items: stretch;
    }

    .custom_grid.two_by_two {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .custom_grid.three_by_two {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

    a {
        color: #101531;
    }
}
.checkbox {
    .p-checkbox-box {
        border: 1px solid #ccc;
        background-color: white;
    }

    &.checked {
        .p-checkbox-box {
            border-color: #4541FF !important;
            background-color: #4541FF !important;
        }
    }

    &.checkbox_error {
        .p-checkbox-box {
            border-color: red !important;
        }
    }
}