.paymentTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: #101531;
}

.tabs {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-bottom: .1rem solid #B4B4B4;
  margin: 1rem 0;

  .tab {
    display: flex;
    align-items: center;
    padding: .5rem;
    cursor: pointer;

    span {
      margin-left: .3rem;
      color: #101531;
    }
  }

  .selected {
    font-weight: 700;
    border-bottom: .15rem solid #101531;
  }
}

.balance {
  padding: 1.2rem;
  border: .1rem solid #B4B4B4;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;

  &Price {
    display: flex;
    flex-direction: column;
    gap: .25rem;

    .price {
      color: #3B8329;
      font-weight: 700;
    }
  }
}