@import 'assets/styles/config/mixin.scss';

.stripeCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #f7f7f7;
	padding: 1rem;
	font-weight: 700;
}

.balanceCard {
	display: flex;
	flex-direction: column;
	background-color: #f7f7f7;
	padding: 1rem;
	margin: 1rem 0;

	@include mq('tablet') {
		flex-direction: row;
	}

	.current {
		flex:2;
		display: flex;
		flex-direction: column;

		.price {
			font-size: 1.2rem;
			font-weight: 700;
			margin: .5rem 0;
		}
	}

	.withdraw {
		flex:1;
		flex-direction: column;
		align-items: center;
	}

	.button {
		flex: 1;
		display: flex;
		align-items: flex-end;
		margin: 1rem 0;

		@include mq('tablet') {
			margin: 0 0 0 .5rem;
		}
	}
}