@import 'assets/styles/config/mixin.scss';

.printFile {
	&Wrapper {
		display: flex;
		max-width: 100%;
		border: 2px solid var(--surface-400);
		border-radius: 0.5rem;
		padding: 0.3rem;
		transition: background-color 100ms ease-in-out;
		position: relative;

		&:hover {
			background-color: $c-light;
		}
	}

	&Btn {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		align-items: center;
		padding: 0.5rem 2rem;
		flex-shrink: 0;

		& > span {
			font-size: 0.875rem;
			font-weight: 600;
		}
	}

	&Divider {
		width: 0.125rem;
		min-height: 100%;
		margin: 0 1rem 0 0;
		background-color: var(--surface-400);
	}

	&Info {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		justify-content: center;
		padding-right: 0.7rem;
		width: 100%;

		.printTitle {
			font-size: 0.875rem;
			font-weight: 600;
		}

		.printAccepts {
			font-size: 0.75rem;
			font-weight: 600;
			color: var(--surface-400);
		}
	}
}
