.outsStockBtn {
	padding: 1px;
	border: .5px solid #FF9F1C;
	border-radius: 5px 5px 0px 0px;
}

.entriesStockBtn {
	padding: 1px;
	border: .5px solid #1565C0;
	border-radius: 5px 5px 0px 0px;
}