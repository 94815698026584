@import 'assets/styles/config/mixin.scss';

.table {

	&Wrapper {
		box-shadow: 0 0.2rem 0.5rem rgba($c-black, 0.05);
		border-bottom: 1px solid rgba($c-black, 0.2);
	}

	&Header {
		display: flex;
		gap: 1.5rem;
		align-items: center;
		height: 4.2rem;

		&Titles {
			flex: 1 1 calc(100% - 19rem);
			display: flex;
			align-items: center;
			gap: 1rem;

			&Name {
				flex: 1 1 40%;
			}

			&PrintramPrice {
				flex: 1 1 30%;
			}

			&GroupPrice {
				flex: 1 1 30%;
			}
		}
	}

	&Body {
		position: relative;

		&Wrapper {
			padding: 1rem 0;
			display: flex;
			align-items: center;
		}

		&Items {
			flex: 1 1 calc(100% - 20rem);
			display: flex;
			align-items: center;
			gap: 1rem;
		}

		&Item {

			&Wrapper {
				display: block;
				transition: background-color 100ms ease-in-out;

				&.selected {
					background-color: rgba(lightblue, 0.3);
				}


				&:not(:first-child) {
					border-top: 1px solid rgba($c-black, 0.2);
				}

				&:hover {
					background-color: darken($c-white, 4%);

					& .tableBodyItems {
						h6 {
							color: $c-primary;
						}
					}
				}

				&.loadMore {
					min-height: 5rem;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: space-evenly;
					gap: 0.5rem;
					cursor: auto;

					&:hover {
						background-color: transparent;
					}
				}
			}

			&Name {
				flex: 1 1 40%;
				display: flex;
				align-items: center;


				span {

					font-size: .9rem;
					font-weight: 400;
				}
			}

			&PrintramPrice {
				flex: 1 1 30%;
				display: flex;
				align-items: center;

				span {
					font-size: .9rem;
					font-weight: 400;
				}
			}

			&GroupPrice {
				flex: 1 1 30%;
				display: flex;
				align-items: center;

				span {
					font-size: .9rem;
					font-weight: 400;
				}
			}
		}
	}
}

.filters {
	display: flex;
	flex-direction: row;
	gap: 1rem;

	.savePrices {
		flex: 1;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
	}
}

.groupPriceOverlay {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}