@import 'assets/styles/config/mixin.scss';

.main {
    display: flex;
    justify-content: center;

    .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
		gap: .9rem;
    }
}

.currentPlan {
	display: flex;
	justify-content: center;
	border-radius: .25rem;
    background-color: #ebf0ff;
    padding: 1rem;
	margin: 4rem 0 1rem 0;
	text-align: center;

    &Title {
        font-weight: 500;
    }

	&Name {
		color: #1565C0;
		font-weight: 600;
	}
}

.buttons {
	position: sticky !important;
	bottom: 0;
	display: flex;
	justify-content: flex-end;
}

.saveContainer {
		margin: 2rem 0;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		gap: 3.12rem;
		position: relative;

		&.desktop {
			display: none;
		}

		&.mobile {
			display: flex;
		}

		@include mq('tablet') {
			margin: 1rem 0 3rem;

			&.desktop {
				display: flex;
			}

			&.mobile {
				display: none;
			}
		}

		& > strong {
			font-size: 0.875rem;
			font-weight: 600;
			color: $c-primary;
			margin-bottom: 0.5rem;
		}

		& > .arrow {
			position: absolute;
			top: 88%;
			left: 51.5%;
		}
	}

	.offer {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 1rem;

		span {
			font-size: 1.5rem;
			font-weight: 600;
			text-align: center;
		}

		&Price {
			color: #1565c0;
		}
	}

	.deviceControl {
	display: none !important;

	@include mq('tablet') {
		display: flex !important;
	}
}