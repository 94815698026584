@import 'assets/styles/config/mixin.scss';

.orders {
	&Table {
		&Wrapper {
			box-shadow: 0 0.2rem 0.5rem rgba($c-black, 0.05);
			border-bottom: 1px solid rgba($c-black, 0.2);
		}

		&Header {
			display: flex;
			gap: 1.5rem;
			align-items: center;
			height: 4.2rem;

			&SelectAll {
				flex: 1 1 12rem;
				user-select: none;
				display: flex;
				align-items: center;
			}

			&Titles {
				flex: 1 1 calc(100% - 18.3rem);
				display: flex;
				align-items: center;
				gap: 1rem;

				&Product {
					flex: 1 1 40%;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 5.25rem;
				}

				&Inventory {
					flex: 1 1 20%;
				}

				&DeliveryOptions {
					flex: 1 1 15%;
				}

				&Status {
					flex: 1 1 25%;
				}
			}
		}

		&Body {
			position: relative;

			&Wrapper {
				padding: 1rem 0;
				display: flex;
				align-items: center;
			}

			&Items {
				flex: 1 1 calc(100% - 20rem);
				display: flex;
				align-items: center;
				gap: 1rem;
			}

			&Item {
				&Wrapper {
					display: block;
					transition: background-color 100ms ease-in-out;
					cursor: pointer;

					&:not(:first-child) {
						border-top: 1px solid rgba($c-black, 0.2);
					}

					&:hover {
						background-color: darken($c-white, 4%);

						& .ordersTableBodyItems {
							h6 {
								color: $c-primary;
							}

							& .ordersTableBodyItemInventory {
								color: $c-primary;
							}
						}
					}

					&.loadMore {
						min-height: 5rem;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: space-evenly;
						gap: 0.5rem;
						cursor: auto;

						&:hover {
							background-color: transparent;
						}
					}

					&.external {
						opacity: 0.7;
					}
				}

				&Check {
					flex: 1 1 12rem;
					display: flex;
					align-items: center;
					gap: 0.3rem;

					& > label {
						padding: 0 0.2rem;

						span {
							display: block;

							& + span {
								font-size: 0.875rem;
								color: var(--text-color-secondary);
							}
						}

						&:hover {
							background-color: darken($c-white, 10%);
						}
					}
				}

				&Product {
					flex: 1 1 40%;
					display: flex;
					flex-direction: column;
					gap: 0.3rem;

					h6 {
						max-width: 10rem;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: 0.25rem;
						text-wrap: nowrap;
					}

					& > * {
						margin: 0;
					}

					& > span {
						line-height: 0.875rem;
						font-size: 0.875rem;
						color: var(--text-color-secondary);
					}
				}

				&Inventory {
					flex: 1 1 20%;
					font-size: 0.875rem;
					display: flex;
					flex-direction: column;

					& > .deliveryType {
						border: 1px solid rgba($c-black, 0.2);
						padding: 0.1rem 0.3rem;
						border-radius: 0.2rem;
						background-color: rgba($c-black, 0.03);
						max-width: max-content;
						margin-top: 0.3rem;
					}

					& > .serviceLabelName {
						display: block;
						max-width: 9rem;
						text-wrap: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;

						&.express {
							color: $c-primary;
							font-weight: 500;
						}
					}
				}

				&DeliveryOptions {
					flex: 1 1 15%;
					font-size: 0.875rem;
					display: flex;
					flex-direction: column;
				}

				&Status {
					flex: 1 1 25%;
				}
			}

			&Actions {
				flex: 1 1 11rem;
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}

.dropdownMinWidth {
	min-width: 15rem;
}
