@import 'assets/styles/config/mixin.scss';

.toggleBtn {
	width: 2.2rem;
	height: 2.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	border: none;
	background-color: transparent;
	border-radius: 0.3rem;
	cursor: pointer;
	margin: 0 auto;

	& > span {
		font-weight: bold;
	}

	&:hover {
		outline: none;
		background-color: rgba($c-black, 0.1);
	}
}

.thumbnailImg {
	width: 100%;
	border: 2px solid transparent;
	border-radius: 0.3rem;
	cursor: pointer;
	transition: border-color 100ms ease-in-out;
	scroll-snap-align: start;

	&:hover {
		border-color: rgba($c-primary, 0.3);
	}

	&.selectedImg {
		border-color: $c-primary;
	}
}
