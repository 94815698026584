@import 'assets/styles/config/mixin.scss';

.forms {
	&Table {
		&Wrapper {
			box-shadow: 0 0.2rem 0.5rem rgba($c-black, 0.05);
			border-bottom: 1px solid rgba($c-black, 0.2);
			padding: 0 1.5rem 0 1.5rem;
		}

		&Header {
			display: flex;
			gap: 1.5rem;
			align-items: center;
			height: 4.2rem;

			&Titles {
				flex: 1 1 calc(100% - 19rem);
				display: flex;
				align-items: center;
				gap: 1rem;

				&Website {
					flex: 1 1 25%;
				}

				&EmailPhone {
					flex: 1 1 25%;
				}

				&Comments {
					flex: 1 1 25%;
				}

				&Date {
					flex: 1 1 25%;
					cursor: pointer;
				}
			}
		}

		&Body {
			position: relative;

			&Wrapper {
				padding: 1rem 0;
				display: flex;
				align-items: center;
			}

			&Items {
				flex: 1 1 calc(100% - 20rem);
				display: flex;
				align-items: center;
				gap: 1rem;
			}

			&Item {
				&Wrapper {
					display: block;
					transition: background-color 100ms ease-in-out;


					&:not(:first-child) {
						border-top: 1px solid rgba($c-black, 0.2);
					}

					&:hover {
						background-color: darken($c-white, 4%);

						& .ordersTableBodyItems {
							h6 {
								color: $c-primary;
							}

							& .ordersTableBodyItemInventory {
								color: $c-primary;
							}
						}
					}

					&.loadMore {
						min-height: 5rem;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: space-evenly;
						gap: 0.5rem;
						cursor: auto;

						&:hover {
							background-color: transparent;
						}
					}
				}

				&Website {
					flex: 1 1 25%;
					display: flex;
					flex-direction: column;
					gap: 0.3rem;

					div {
						display: flex;
						flex-direction: column;
					}

					a {
						max-width: 15rem;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: .25rem;
						font-size: 1rem;
						font-weight: 400;
					}
				}

				&EmailPhone {
					flex: 1 1 25%;

					div {
						display: flex;
						flex-direction: column;
					}
				}

				&Comments {
					flex: 1 1 25%;

					h6 {
						max-width: 15rem;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: .25rem;
						font-size: 1rem;
						font-weight: 400;
					}
				}

				&Date {
					flex: 1 1 25%;
					font-size: 0.875rem;
				}
			}
		}
	}
}