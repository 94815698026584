@import 'assets/styles/config/mixin.scss';

.main {

	padding: .5rem;

	@include mq('tablet') {
		padding: 2rem 4rem;
	}

	.name {
		label {
			font-size: .9rem;
		}

		&Input {
			background-color: #F9F9F9;
			border:0;
			border-radius: .5rem;
			width: 100%;
			margin: .5rem 0;
			height: 2.5rem;
			padding-left: .8rem;
			font-size: 1rem;
		}
	}

	.card {
		margin: 1.5rem 0 1.85rem 0;

		label {
			font-size: .9rem;
		}

		&Number {
			display: flex;
			flex-direction: row;
			align-items: center;
			background-color: #F9F9F9;
			border:0;
			border-radius: .5rem;
			height: 2.5rem;
			margin: .5rem 0;

			img {
				padding: 0 .5rem;
			}
		}

		&Input {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: .8rem;
		}
	}

	.expCvc {
		display: flex;
		flex-direction: row;
		gap: 1rem;

		.exp {
			flex:1;

			label {
				font-size: .9rem;
			}

				&Input {
				background-color: #F9F9F9;
				border:0;
				border-radius: .5rem;
				width: 100%;
				margin: .5rem 0;
				height: 2.5rem;
				padding-left: .8rem;
				font-size: 1rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}

		.cvc {
			flex:1;

			label {
				font-size: .9rem;
			}

			&Input {
				background-color: #F9F9F9;
				border:0;
				border-radius: .5rem;
				width: 100%;
				margin: .5rem 0;
				height: 2.5rem;
				padding-left: .8rem;
				font-size: 1rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}

	.buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		margin: 4rem 0 0 0;

		.discardBtn {
			background-color: #F9F9F9;
			color: #79839E;
			font-size: .9rem;
			padding: .75rem 1.75rem;
			border-radius: .5rem;
		}

		.submitBtn {
			background-color: #4050B5;
			color: white;
			font-size: .9rem;
			font-weight: 400;
			padding: .75rem 1.75rem;
			border-radius: .5rem;
		}
	}
}

.dialog {
	max-width: 50rem;
}