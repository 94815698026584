@import 'assets/styles/config/mixin.scss';

.accordion {
	&Side {
		display: none;

		@include mq("desktop"){
			display: block;
		}
	}

	&Tab {
		& * {
			padding: 0;
			margin: 0;
		}
	}

	&SubCategory {
		&Wrapper {
			display: flex;
			flex-direction: column;
		}

		&Item {
			display: block;
			padding: 1rem 2.5rem;
			line-height: 1;
			background-color: var(--bg-white);

			&:first-child {
				border-top: 1px solid var(--surface-200);
			}

			&:hover {
				background-color: var(--surface-hover);
				cursor: pointer;
			}
		}
	}
}