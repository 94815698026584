.payButton {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	gap: 1rem;

	&Description {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: .75rem;
	}

}