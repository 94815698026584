@import 'src/assets/styles/config/mixin.scss';

.socialLoginBtn {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
	color: inherit;
	background-color: white;
	padding: 0.7rem 1.1rem;
	border: 1px solid rgba($c-border, 1);
	border-radius: 4px;
	cursor: pointer;
	transition: box-shadow 100ms ease-in;
	margin-bottom: 1rem;
	font-size: 1rem;

	&:hover {
		box-shadow: 0 2px 7px 0.5px rgba(182, 182, 182, 0.3);
	}
}

.divider {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	.line {
		flex-grow: 1;
		background: #D3D3D3; // Light gray color for the lines
		height: 1px; // Thickness of the lines
	}

	.text {
		color: #808080; // Gray color for the text
		white-space: nowrap; // Prevents text from wrapping
		margin: 0 8px;
	}
}

.loginButton {
	background-color: $c-primary;
	padding: 0.8rem 1.8rem;
	border-radius: 10px;

	&:hover {
		background-color: darken($c-primary, 5%) !important;
	}
}

$formSideWith: 100vw;
$formSideWithLarge: 50vw;
$formSideHeaderHeight: 3rem;

.registerWrapper {
	display: flex;
	color: $c-black;
	background-color: $c-white;
	flex-direction: column; // Default to column for mobile phones

	@media (min-width: 768px) {
		height: 100vh;
		flex-direction: row; // Apply row for tablets and larger screens
	}

	.formSide {
		flex-basis: $formSideWith;

		@include mq('large') {
			flex-basis: $formSideWithLarge;
		}

		.register {
			&Header {
				display: none;

				@include mq('tablet') {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 1rem;
					height: $formSideHeaderHeight;
				}

				.actions {
					display: inline-flex;
					align-items: center;
					gap: 0.6rem;

					.signInBtn {
						color: inherit;
						background-color: white;
						padding: 0.2rem 1rem;
						border: 1px solid rgba($c-black, 0.4);
						border-radius: 4px;
						cursor: pointer;
						transition: box-shadow 100ms ease-in;

						&:hover {
							box-shadow: 0 2px 4px rgba($c-black, 0.4);
						}
					}
				}
			}

			&Main {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 1.5rem;
				width: 100%;
				max-width: 80%;
				padding: 1rem 0;
				margin: auto;

				& > * {
					width: 100%;
				}

				@include mq('tablet') {
					height: calc(100% - $formSideHeaderHeight);
				}

				@include mq('large') {
					max-width: 50%;
				}

				.description {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					gap: 0.5rem;

					& > * {
						margin: 0;
					}

					.title {
						font-size: 1.6rem;
					}
				}

				.socialAction {
					&Btn {
						width: 100%;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						gap: 0.5rem;
						color: inherit;
						background-color: white;
						padding: 0.7rem;
						border: 1px solid rgba($c-black, 0.4);
						border-radius: 4px;
						cursor: pointer;
						transition: box-shadow 100ms ease-in;

						&:hover {
							box-shadow: 0 4px 8px rgba($c-black, 0.4);
						}
					}

					&Footer {
						position: relative;
						height: 1px;
						background-color: rgba($c-black, 0.4);
						margin-top: 1rem;

						&::after {
							content: 'OR';
							position: absolute;
							font-weight: 500;
							background-color: $c-light;
							width: max-content;
							line-height: 1;
							top: -0.5rem;
							display: flex;
							left: 50%;
							transform: translateX(-50%);
							padding: 0 0.8rem;
						}
					}
				}

				.urlActions {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 1.5rem;

					// @include mq('tablet') {
					// 	display: none;
					// }
				}
			}

			&Form {
				display: flex;
				flex-direction: column;
				gap: 1rem;

				.formGroupContainer {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					gap: 1rem;

					@include mq('tablet') {
						flex-direction: row;
					}
				}

				.formGroup {
					display: inline-flex;
					flex-direction: column;
					gap: 0.3rem;
					width: 100%;
				}
			}
		}
	}

	.imageSide {
		flex-basis: calc(100vw - $formSideWith);

		@include mq('large') {
			flex-basis: calc(100vw - $formSideWithLarge);
		}

		.image {
			height: 100%;
			background-image: url(../../../public/pngs/auth-imgs/login-side-img.png);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.mobileImageSide {
		flex-basis: calc($formSideWith);
		background-color: #EBF0FF;

		@media (max-width: 500px) {
			min-height: 678px;
		}

		@media (min-width: 768px) {
			display: none !important;
			flex-basis: calc($formSideWith);
		}

		.image {
			height: 100%;
			width: 100%;
			background-image: url(../../../public/pngs/auth-imgs/login-side-img-mobile-lg.png);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}
