.previewDialog {
	width: 100%;
	max-width: 70rem !important;
	height: 100%;
	max-height: 80vh;
	overflow: hidden;
	text-align: center;

	& .pdf {
		width: 95%;
		height: 98%;
		border: 0;
	}
}
