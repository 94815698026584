.productListItem {
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 0;
    width: 100%;

    img {
        width: 150px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin-right: 2rem;
    }

    .productListDetail {
        flex: 1 1 0;
        margin-left: 1rem;
    }

    .productListAction {
        display: flex;
        flex-direction: column;
    }

    .productPrice {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        align-self: flex-end;
    }
}

.productName {
    font-size: 1.5rem;
    font-weight: 700;
}

.productDescription {
    margin: 0 0 1rem 0;
}

.productCategoryIcon {
    vertical-align: middle;
    margin-right: 0.5rem;
}

.productCategory {
    font-weight: 600;
    vertical-align: middle;
}

.pushedProduct {
	border: 1px solid green;
	border-radius: .75rem;
	margin-left: 1rem;
	padding: .2rem .5rem;
	font-size: small;
	font-weight: 500;
	color: green;
}
