@import 'assets/styles/config/mixin.scss';

.wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	gap: 1rem;
	border: 1px solid rgba($c-black, 0.2);
	border-radius: 0.2rem;
	padding: 1rem;

	& > img {
		max-width: 3.75rem;
		max-height: 3.75rem;
		width: 100%;
		height: 100%;
		aspect-ratio: 1;

		border-radius: 50%;
	}

	& > .icon {
		font-size: 2rem;
	}

	a {
		color: #4541FF;
	}
}
