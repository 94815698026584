@import 'assets/styles/config/mixin.scss';

.search {
	&Container {
		--search-height: 2.813rem;
		height: var(--search-height);
		position: relative;
		width: 100%;

		& > div {
			position: absolute;
			top: 0;
			left: 0;
			width: calc(100% - var(--btn-width));
			border-right: 0;
		}
	}

	&Wrapper {
		--search-height: 2.813rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 0.4rem;
		padding: 0 1rem 0 0.75rem;
		height: var(--search-height);
		border: 1px solid rgba($c-black, 0.5);
		border-radius: calc(var(--search-height) / 2);
		background-color: $c-light;

		&.focusSearch {
			outline: 1.5px solid $c-primary;
			background-color: $c-white;
		}

		&.btnExists {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&Input {
		--input-height: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		border: none;
		font-size: 1rem;
		background-color: transparent;

		&:focus {
			outline: none;
		}
	}

	&Btn {
		position: absolute;
		right: 0;
		border-radius: 0 calc(var(--search-height) / 2) calc(var(--search-height) / 2) 0 !important;
		height: 100% !important;
		border-color: rgba($c-black, 0.5) !important;
		// border-left: 1px solid currentColor;
		// border-radius: 0;
		// padding-left: 2rem;
	}
}
