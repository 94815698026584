.main {
	width: 100%;
	height: 100%;
	min-height: 90vh;
	border: 1px solid #E5E7EB;
	border-radius: .5rem;
	background-color: #FFFFFF;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: .75rem;
		border-bottom: 1px solid #E5E7EB;

		.closeIcon {
			border: 1px solid #E5E7EB;
			border-radius: .5rem;
			padding: .15rem .2rem .05rem .2rem;
			cursor: pointer;
		}

		.addBtn {
			border: 1px solid #E5E7EB;
			border-radius: .5rem;
			padding: .5rem 1rem;
			font-size: .9rem;
			font-weight: 400 !important;
			color: #FFFFFF;
			background-color: #4541FF;
			font-weight: 500;
			cursor: pointer;
		}
	}

	.body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&Inner {
			width: 100%;
			max-width: 50rem;
			margin-top: 4rem;
			padding: 1rem;
		}

		h1 {
			font-size: 1.5rem;
			font-weight: 600;
			color: #101531;
		}

		.locAddMenus {
			padding: 1rem 0;
			border-bottom: 1px solid #E5E7EB;
			width: 100%;

			&Header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;

				& > span {
					font-weight: 600;
				}
			}

			&Body {
				& > span {
					display: block;
					margin: .5rem 0 1.5rem;
				}

				.address {
					font-size: 1rem;
					font-weight: 600;
					margin-top: 2rem;
				}

				.input {
					margin-bottom: 1.5rem;

					&Rows {
						display: flex;
						flex-direction: row;
						gap: 1rem;
						margin-bottom: 1.25rem;

						.inputRow {
							flex: 1;
						}
					}

					&Half {
						margin-bottom: 1.25rem;
						width: calc(50% - .5rem);
					}

					&Label {
						margin: .5rem 0;
						font-size: .9rem;
						font-weight: 600;
					}
				}

				.settingsContainer {
					display: flex;
					flex-direction: row;
					margin-top: 2rem;

					.descriptions {
						flex: 1;

						span {
							display: block;
						}

						.settingsName {
							font-weight: 600;
							font-size: .9rem;
						}

						.settingsDesc {
							margin-top: .5rem;
							font-weight: 400;
							font-size: .8rem;
						}
					}

					.setting {
						display: flex;
						align-items: center;
						justify-content: end;
					}
				}
			}
		}
	}
}