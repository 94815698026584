.btn {
	&Status {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 100px;
		padding: 0.5rem 1rem;
		border-radius: 5px;
		background: var(--surface-0);
		border: 1px solid var(--surface-100);
		background: linear-gradient(to left, var(--surface-0) 50%, var(--surface-100) 50%);
		background-size: 200% 100%;
		background-position: right bottom;
		transition: background-position 0.5s ease-out;
		color: #000;
		cursor: pointer;
		font-weight: 500;
		font-family: inherit;
		line-height: 1;

		&:hover {
			background-color: transparent;
			background-position: left bottom;
		}

		&:focus {
			box-shadow: 0 0 0 1px var(--green-400);
		}

		&.active {
			border-color: var(--border-color-hex);
		}
	}
}
